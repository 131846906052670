import BackendApi from "@api/BackendApi.ts";
import { AuthHeaders } from "@auth";
import { PathCollection as BackendPathCollection, Point, Project } from "@models/backend";
import { Model, PathCollection, PathCollectionInput } from "@models/project";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { isVoidPathCollection } from "@utils/project/zone.ts";
import { Curve as V3dCurve, PathInput, Zone } from "@variant-tech/pattern-derivation";
import { Curve, File3dm } from "rhino3dm";

const CURVE_SAMPLE = 50;

export function createPathCollection(): PathCollectionInput {
  return {
    controlVectors: [],
    isLoop: false,
    type: "Path",
    normalsAndTangents: [],
    points: [],
    samples: [],
    segments: [],
    vertices: new Float32Array(0),
    usage: null,
  };
}

export async function createPathCollectionFromState(
  model: Model,
  pathCollection: BackendPathCollection,
): Promise<PathCollection> {
  const v3dApi = getV3dApi();
  let curve: V3dCurve | Zone | undefined = undefined;
  const { type = "Path", controlVectors, points, isLoop } = pathCollection.paths[0];
  const { id, name, attributes, usage } = pathCollection;

  const payload = {
    type,
    controlVectors: points.map(
      (_, i) =>
        controlVectors[i] ?? [
          [0, 0, 0],
          [0, 0, 0],
        ],
    ),
    points,
    isLoop: !!isLoop,
    isVoid: isVoidPathCollection({ attributes }, model),
  };

  if (pathCollection.usage == "zone") {
    curve = await v3dApi.generateZone(model.mesh3DBase, payload);
  } else {
    curve = await v3dApi.generateCurve(model.mesh3DBase, payload);
  }

  return {
    id,
    name,
    attributes,
    usage,
    ...curve,
  } as PathCollection;
}

export async function savePathCollection(
  headers: AuthHeaders["headers"],
  projectId: Project["id"],
  modelId: Model["id"],
  collection: PathCollection,
) {
  const { id, name, attributes, usage, type, points, controlVectors, isLoop } = collection;

  const request = {
    headers,
    params: {
      projectId: projectId,
      modelId: modelId,
      id,
    },
    body: {
      paths: [{ id, type, points, controlVectors, isLoop }],
      name,
      attributes,
      usage,
    },
  };

  return BackendApi.updatePathCollection(request);
}

export function sortPathCollections(collections: Array<PathCollection>, order: Model["zoneOrder"]) {
  const res: Array<PathCollection> = [];
  const found: Array<string> = [];
  if (order) {
    const sortedOrder = order.toSorted((a, b) => a[1] - b[1]);
    for (const pos of sortedOrder) {
      const id = pos[0];
      const collection = collections.find((collection) => collection.id == id);
      if (collection) {
        res.push(collection);
        found.push(id);
      }
    }
  }
  for (const collection of collections) {
    if (found.findIndex((id) => id === collection.id) === -1) {
      res.push(collection);
    }
  }
  return res;
}

export function getPathCollectionsFrom3dm(file3dm: File3dm): Array<PathInput> {
  const objects = file3dm.objects();
  const collections: Array<PathInput> = [];
  for (let i = 0; i < objects.count; i++) {
    const geometry = objects.get(i).geometry();
    if (geometry.objectType === window.rhino!.ObjectType.Curve) {
      const curve = geometry as Curve;
      const domain = curve.domain;
      const step = (domain[1] - domain[0]) / (CURVE_SAMPLE - 1);
      const points: Array<Point> = [];
      for (let j = 0; j < CURVE_SAMPLE; j++) {
        const t = step * j;
        points.push(curve.pointAt(t) as Point);
      }
      collections.push({ points, type: "Path", isLoop: curve.isClosed });
    }
  }
  return collections;
}
