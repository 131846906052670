import { createContext, useContext } from "react";
import pen from "/cursors/pen.png";
import penCoincident from "/cursors/penCoincident.png";
import penMinus from "/cursors/penMinus.png";
import select from "/cursors/select.png";
import selectMinus from "/cursors/selectMinus.png";
import selectBending from "/cursors/selectBending.png";
import addSelection from "/cursors/addSelection.png";

export const cursorMap = {
  pen: `url(${pen}), auto`,
  coincident: `url(${penCoincident}), auto`,
  remove: `url(${penMinus}), auto`,
  select: `url(${select}), auto`,
  selectMinus: `url(${selectMinus}), auto`,
  selectBending: `url(${selectBending}), auto`,
  addSelection: `url(${addSelection}), auto`,
};

export type CursorMapKeys = keyof typeof cursorMap;

export type CursorContextProps = {
  cursor: string;
  setCursor: (cursor: CursorMapKeys) => void;
};

export const CursorContext = createContext<CursorContextProps>({
  cursor: cursorMap["select"],
  setCursor: () => {},
});

export const CursorConsumer = CursorContext.Consumer;

export const useCustomCursor = () => useContext(CursorContext);
