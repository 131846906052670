import { Box } from "@chakra-ui/react";
import { Dropdown, DropdownItem, spacing, useSemanticTokens } from "@design-system";
import { PostprocessorDocument } from "@models/backend";

interface ConfigSelectorProps {
  selectedConfig: PostprocessorDocument | undefined;
  postprocessorDocuments: PostprocessorDocument[];
  onSelect: (configId: string) => void;
  isDisabled?: boolean;
  maxWidth?: string;
}

export function ConfigSelector({
  selectedConfig,
  postprocessorDocuments,
  onSelect,
  maxWidth = "100%",
}: ConfigSelectorProps) {
  const semanticTokens = useSemanticTokens();

  return (
    <Box flex={1} maxWidth={maxWidth} minWidth={0}>
      {" "}
      <Dropdown
        variant="classic-dropdown"
        labelVariant="2xs-regular"
        label={selectedConfig?.name ?? "no default config found"}
        buttonStyleProps={{
          size: "xs",
          width: "100%",
          height: "24px",
          paddingX: spacing.space["200"],
          paddingY: spacing.space["50"],
          bg: semanticTokens.surface.classic.secondary,
          maxWidth: "100%",
        }}
        menuListStyleProps={{
          zIndex: 2,
          maxHeight: "200px",
          overflowY: "auto",
          paddingY: spacing.space["100"],
          maxWidth: maxWidth,
        }}
      >
        {postprocessorDocuments.map((doc) => (
          <DropdownItem
            variant="2xs-regular"
            key={doc.id}
            label={doc.name}
            value={doc.id}
            selectedValue={selectedConfig?.id ?? ""}
            setSelectedValue={onSelect}
          />
        ))}
      </Dropdown>
    </Box>
  );
}
