import { Box, Grid, Text } from "@chakra-ui/react";
import { Dropdown, DropdownItem, spacing, useSemanticTokens } from "@design-system";
import { ColorEntry, MachineColorEntry, Machine } from "@models/backend";
import { ColorEntryLabel, PaletteColorEntryLabel } from "../sidebars/Common";

interface ColorPickerProps {
  colorEntry: MachineColorEntry;
  machine: Machine;
  colorEntryOptions: ColorEntry[];
  selectedValue: number;
  onColorChange: (code: number) => void;
  isDisabled?: boolean;
}

export function ColorPicker({
  colorEntry,
  machine,
  colorEntryOptions,
  selectedValue,
  onColorChange,
}: ColorPickerProps) {
  const semanticTokens = useSemanticTokens();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Dropdown
        variant="classic-dropdown"
        label={<ColorEntryLabel colorEntry={colorEntry} />}
        buttonStyleProps={{
          display: "flex",
          boxSizing: "border-box",
          height: "24px",
          width: "84px",
          alignItems: "center",
          gap: spacing.space["200"],
          pt: spacing.space["300"],
          pb: spacing.space["300"],
          pr: spacing.space["100"],
          pl: spacing.space["200"],
          bg: semanticTokens.surface.secondary,
          justifyContent: "space-between",
        }}
        menuListStyleProps={{
          width: "165px",
          minWidth: "0px",
          mt: spacing.space["100"],
        }}
      >
        <Box p={spacing.space["200"]} width="165px">
          <Text mb={spacing.space["100"]} variant="2xs-regular" color={semanticTokens.text.classic.primary}>
            {machine.name}
          </Text>
          <Grid templateColumns="repeat(4, 1fr)" gap={spacing.space["100"]}>
            {colorEntryOptions.map((option) => (
              <DropdownItem
                styleProps={{
                  padding: 0,
                  margin: 0,
                  width: "34px",
                  height: "24px",
                }}
                key={option.code}
                label={`${option.code}`}
                value={option.code}
                selectedValue={selectedValue}
                setSelectedValue={() => onColorChange(option.code)}
                content={<PaletteColorEntryLabel colorEntry={option} />}
                hideSelectedCheck
              />
            ))}
          </Grid>
        </Box>
      </Dropdown>
    </Box>
  );
}

export default ColorPicker;
