import { useAuth } from "@auth";
import { useEffect, useState } from "react";

export type BackendApiResponseSupplier<Req, Res> = (request: Req) => Promise<Res>;
export type StatusType = "in-progress" | "success" | "error";

export type ApiResult<T> = {
  isLoading: boolean;
  status?: StatusType;
  result?: T;
  error?: Error;
};

export function useBackendApi<Req extends RequestInit, Res>(
  backendApiRequestFunction: BackendApiResponseSupplier<Req, Res>,
  requestInit: Omit<Req, "headers"> & { headers?: RequestInit["headers"] },
  deps: unknown[],
): ApiResult<Res> {
  const { headers: authHeaders = {} } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [result, setResult] = useState<Res>();
  const [status, setStatus] = useState<StatusType>();

  useEffect(() => {
    setIsLoading(true);
    setStatus("in-progress");
    const requestHeaders = requestInit.headers ?? {};
    const options = {
      ...requestInit,
      headers: { ...requestHeaders, ...authHeaders },
    };

    backendApiRequestFunction(options as Req)
      .then((data) => {
        setResult(data);
        setStatus("success");
      })
      .catch((error) => {
        setError(error);
        setStatus("error");
      })
      .finally(() => setIsLoading(false));
  }, deps);

  return {
    isLoading,
    error,
    result,
    status,
  };
}
