import { chakra } from "@chakra-ui/react";

export const ArrowIcon = chakra((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M9.00033 3.3335L13.667 8.00015L9.00033 12.6668M2.33366 8.00015L13.3337 8.00016"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
));

ArrowIcon.displayName = "ArrowIcon";
