import { useProgressToast, useTranslation } from "@hooks";
import { Model as BackendModel } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { useModelsStore } from "@state/models";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { createPathCollectionFromState, load3dmFile, getPathCollectionsFrom3dm } from "@utils";
import { File3dm } from "rhino3dm";
import { useBackendPathCollection } from "./useBackendPathCollection";

export function usePathCollectionLoader() {
  const { pushPathCollections, pathCollections } = useModelsStore(({ pushPathCollections, pathCollections }) => ({
    pushPathCollections,
    pathCollections,
  }));
  const { createBackendPathCollection } = useBackendPathCollection();
  const toast = useProgressToast();
  const { t } = useTranslation("project.sidebar.layers");
  const { t: tp } = useTranslation("hooks.rhino3dm.load.progress");

  async function loadPathCollections(model: Model, projectModel: BackendModel) {
    const collections = await Promise.all(
      projectModel.pathCollections.map(
        async (pathCollection) => await createPathCollectionFromState(model, pathCollection),
      ),
    );
    pushPathCollections(model.id, collections);
    return collections;
  }

  async function addPathCollectionsFrom3dm(projectId: string, model: Model, file3dm: File3dm) {
    const onlyPaths = getPathCollectionsFrom3dm(file3dm);

    const startIdx = Object.keys(pathCollections[model.id]?.collections ?? {}).length;
    const collections = await Promise.all(
      onlyPaths.map(async (path, idx): Promise<PathCollection> => {
        const name = (path.isLoop ? t("types.closed") : t("types.curve")) + ` ${startIdx + idx}`;
        const curve = await getV3dApi().generateCurve(model.mesh3DBase, path);
        const collection: Omit<PathCollection, "id"> = {
          ...curve,
          name,
          attributes: {},
          usage: null,
        };
        const response = await createBackendPathCollection(projectId, model.id, collection);

        return {
          ...curve,
          ...response,
        } as PathCollection;
      }),
    );

    pushPathCollections(model.id, collections);
  }

  async function loadPathCollectionsFrom3dm(projectId: string, model: Model, file: File) {
    const { update } = toast(tp, { title: file.name, status: "in-progress" });
    try {
      if (!window.rhino) {
        window.rhino = await window.rhino3dm();
      }
      const file3dm = await load3dmFile(file);
      update({
        message: tp(`status.in-progress.path-collections`),
        status: "in-progress",
      });
      await addPathCollectionsFrom3dm(projectId, model, file3dm);
      update({ status: "success" });
    } catch (error) {
      console.log(error);
      update({ status: "error", message: tp("status.error") });
    }
  }

  return { loadPathCollections, loadPathCollectionsFrom3dm };
}
