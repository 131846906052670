import { Button, HStack, Input, Text, VStack } from "@chakra-ui/react";
import { Modal, spacing, useSemanticTokens } from "@design-system";
import { useTranslation } from "@hooks";
import { withLoadingAndErrorHandling } from "@utils";
import { useEffect, useRef, useState } from "react";

interface RenamePanelProps {
  isOpen: boolean;
  onClose: () => void;
  currentName: string;
  onSubmit: (newName: string) => Promise<void>;
  mode: "rename" | "saveNew";
  isSubmitting?: boolean;
}

export function RenamePanel({ isOpen, onClose, currentName, onSubmit, mode, isSubmitting = false }: RenamePanelProps) {
  const [newName, setNewName] = useState(currentName);
  const [error, setError] = useState<Error | null>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("projectProperties.postprocessorConfig");
  const semanticTokens = useSemanticTokens();

  useEffect(() => {
    if (isOpen) {
      setNewName(mode === "saveNew" ? `${currentName}-copy` : currentName);
      setError(null);
    }
  }, [isOpen]);

  const handleSubmit = async () => {
    withLoadingAndErrorHandling(
      () => {},
      setError,
      async () => {
        await onSubmit(newName);
        onClose();
      },
    );
  };

  const handleClose = () => {
    setNewName(currentName);
    setError(null);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      header={mode === "rename" ? "Rename Post-processor configuration" : "Save new Post-processor configuration"}
      closable={true}
      closeOnOverlayClick={false}
    >
      <VStack spacing={spacing.space["300"]} align="stretch">
        <Input
          ref={initialFocusRef}
          placeholder={mode === "rename" ? currentName : `${currentName}-copy`}
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          autoFocus
          bg={semanticTokens.surface.classic.secondary}
          borderColor={semanticTokens.border.classic.primary}
          _hover={{ borderColor: semanticTokens.border.classic.secondary }}
          _focus={{ borderColor: semanticTokens.border.classic.tertiary }}
        />
        {error && (
          <Text color="red" variant="2xs-regular">
            {error.message ?? "An error occurred"}
          </Text>
        )}
        <HStack spacing={spacing.space["300"]} justify="flex-end">
          <Button variant="outline" size="xs" onClick={handleClose} isDisabled={isSubmitting}>
            {t("renamePanel.cancel.label")}
          </Button>
          <Button
            variant="primary"
            size="xs"
            onClick={handleSubmit}
            isDisabled={!newName || newName === currentName || isSubmitting}
            isLoading={isSubmitting}
          >
            {t(mode === "rename" ? "buttons.rename.label" : "buttons.saveNew.label")}
          </Button>
        </HStack>
      </VStack>
    </Modal>
  );
}
