import "@/globals";
import { useAsyncLoader } from "@hooks";
import { analytics } from "@integrations/analytics";
import { initializeV3dApi } from "@variant-tech/pattern-derivation";

async function initV3dApi() {
  window.v3dApi = await initializeV3dApi(false, true, ({ durationInMillis, methodName, success }) => {
    analytics.track("V3D_WASM_WORKER_CALL", {
      Method: methodName,
      Duration: durationInMillis,
      Success: success,
    });
  });

  return window.v3dApi;
}

export function getV3dApi() {
  if (!v3dApi) {
    throw new Error("V3dApi is not initialized");
  }

  return v3dApi;
}

export const useV3dApi = () => useAsyncLoader(initV3dApi);
