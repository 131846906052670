import { colors } from "@design-system/colors";
import { PathCollection } from "@models/project";
import { useModelsStore } from "@state/models";
import { Mesh, Vector3 } from "three";
import { DirectionArrow } from "./DirectionArrow";

export type DirectionalityHelperProps = {
  mesh: Mesh;
  guideSource: PathCollection;
};

function DirectionalityHelper({ mesh, guideSource }: DirectionalityHelperProps) {
  const { selectedObjects, hoveredObject } = useModelsStore();
  const isSelected = guideSource.id ? !!selectedObjects.find(({ id }) => id === guideSource.id) : false;
  const isHovered = !isSelected && hoveredObject?.id === guideSource.id;
  const color = isSelected || isHovered ? colors.blue["600"] : colors.green["600"];
  const samples = "samples" in guideSource ? guideSource.samples : [];
  const vectors = samples.map((v) => new Vector3(...v));

  return vectors.map((vector, index) => {
    if (index === vectors.length - 1) {
      return null;
    }

    const dir = vectors[index + 1].clone().sub(vector.clone()).normalize();
    const origin = vector.clone();
    const length = vector.distanceTo(vectors[index + 1]);
    const coneLength = length < 0.5 ? length : 0.5;
    const coneRadius = 0.2;

    return (
      <DirectionArrow
        key={index}
        mesh={mesh}
        collectionId={guideSource.id}
        dir={dir}
        origin={origin}
        length={length}
        coneLength={coneLength}
        coneRadius={coneRadius}
        color={color}
      />
    );
  });
}

export default DirectionalityHelper;
