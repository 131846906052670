import { Box, HStack, Text } from "@chakra-ui/react";
import { spacing, useSemanticTokens } from "@design-system";
import { KnitStructure, MachineColorEntry, Machine } from "@models/backend";
import { ArrowIcon } from "@fragments/application-context-menu/icons/ArrowIcon";
import { LockIcon } from "@chakra-ui/icons";
import { Row } from "@tanstack/react-table";
import { ColorPicker } from "./ColorPicker";

interface StructureRowProps {
  row?: Row<KnitStructure>;
  colorEntry?: MachineColorEntry;
  machine?: Machine;
  colorEntryOptions?: MachineColorEntry[];
  onColorChange?: (structure: KnitStructure, code: number) => void;
  isDisabled?: boolean;
  isHeader?: boolean;
}

export function StructureRow({
  row,
  colorEntry,
  machine,
  colorEntryOptions,
  onColorChange,
  isDisabled = false,
  isHeader = false,
}: StructureRowProps) {
  const semanticTokens = useSemanticTokens();

  if (isHeader) {
    return (
      <HStack
        height="40px"
        spacing={0}
        align="center"
        bg={semanticTokens.surface.primary}
        borderBottom={`1px solid ${semanticTokens.border.classic.primary}`}
      >
        <Box px={spacing.space["200"]} width="279px" height="40px" display="flex" alignItems="center">
          <HStack spacing={spacing.space["100"]}>
            <LockIcon color={semanticTokens.text.secondary} boxSize="12px" />
            <Text color={semanticTokens.text.secondary} variant="2xs-regular">
              Structures
            </Text>
          </HStack>
        </Box>

        <Box width="40px" height="40px" display="flex" justifyContent="center" alignItems="center">
          <ArrowIcon color="transparent" />
        </Box>

        <Box px={spacing.space["200"]} width="84px" height="40px" display="flex" alignItems="center">
          <Text color={semanticTokens.text.secondary} variant="2xs-regular" whiteSpace="nowrap">
            Bitmap color
          </Text>
        </Box>
      </HStack>
    );
  }

  if (!row || !colorEntry || !machine || !colorEntryOptions || !onColorChange) {
    return null;
  }

  return (
    <HStack
      height="40px"
      spacing={0}
      align="center"
      bg={semanticTokens.surface.primary}
      borderBottom={`1px solid ${semanticTokens.border.classic.primary}`}
    >
      <Box px={spacing.space["200"]} width="279px" height="40px" display="flex" alignItems="center">
        <HStack spacing={spacing.space["200"]}>
          <Box
            width="16px"
            height="16px"
            backgroundColor={row.original.color || "transparent"}
            border={`1px solid ${semanticTokens.border.classic.primary}`}
            flexShrink={0}
          />
          <Text color={semanticTokens.text.primary} variant="2xs-regular" noOfLines={1}>
            {row.original.name}
          </Text>
        </HStack>
      </Box>

      <Box width="40px" height="40px" display="flex" justifyContent="center" alignItems="center">
        <ArrowIcon color={semanticTokens.icons.primary} />
      </Box>

      <Box
        px={spacing.space["200"]}
        maxWidth="100px"
        height="40px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ColorPicker
          colorEntry={colorEntry}
          machine={machine}
          colorEntryOptions={colorEntryOptions}
          selectedValue={colorEntry.code}
          onColorChange={(code) => onColorChange(row.original, code)}
          isDisabled={isDisabled}
        />
      </Box>
    </HStack>
  );
}

export default StructureRow;
