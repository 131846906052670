import { Box, VStack } from "@chakra-ui/react";
import { spacing } from "@design-system";
import { useSemanticTokens } from "@design-system";
import { ActiveUserInfo } from "./ActiveUsers";
import { UserItem } from "./UserItem";

interface OverflowTooltipContentProps {
  users: ActiveUserInfo[];
}

export const OverflowTooltipContent = ({ users }: OverflowTooltipContentProps) => {
  const semanticTokens = useSemanticTokens();

  return (
    <Box overflowY="auto" bg={semanticTokens.surface.primary} boxShadow="lg" overflow="hidden">
      <VStack align="stretch" spacing={spacing.space[100]} py={spacing.space[100]} px={spacing.space[100]}>
        {users.map((user) => (
          <UserItem key={user.email} {...user} />
        ))}
      </VStack>
    </Box>
  );
};
