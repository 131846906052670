import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useToast } from "@design-system";
import {
  PostprocessorConfigRenameState,
  RenameConfigOptions,
  UsePostprocessorConfigProps,
  UsePostprocessorConfigReturn,
} from "@fragments/project/post-processor/PostprocessorConfigTypes";
import { useModelUpdate, useTranslation } from "@hooks";
import { KnitStructure, PostprocessorDocument } from "@models/backend";
import { useModelsStore } from "@state";
import { useCallback, useState } from "react";

export function usePostprocessorConfig({
  model,
  project,
  postprocessorDocuments,
}: UsePostprocessorConfigProps): UsePostprocessorConfigReturn {
  const createToast = useToast();
  const { t } = useTranslation("projectProperties.postprocessorConfig");
  const [renameState, setRenameState] = useState<PostprocessorConfigRenameState>({
    isUpdating: false,
    isRenameModalOpen: false,
    renameMode: "rename",
  });

  const modelConfigId = model?.attributes?.postprocessorDocument?.postprocessorDocumentId;
  const defaultConfig = postprocessorDocuments.find((config) => config.system === true);
  const currentConfig = postprocessorDocuments.find((config) => config.id === modelConfigId) || defaultConfig;
  const currentConfigEntries = currentConfig?.entries
    .map((entry) => entry.knitStructure)
    .sort((a, b) => {
      const indexA = defaultConfig?.entries.findIndex((item) => item.knitStructure.id === a.id);
      const indexB = defaultConfig?.entries.findIndex((item) => item.knitStructure.id === b.id);
      if (indexA && indexB) return indexA - indexB;
      else return 0;
    });

  const { headers } = useAuth();
  const { changeAttributes } = useModelUpdate({ modelId: model.id, projectId: project.id });

  const handleConfigSelection = useCallback(
    async (documentId: string) => {
      try {
        await changeAttributes({
          postprocessorDocument: {
            postprocessorDocumentId: documentId,
          },
        });
      } catch (error) {
        createToast({
          title: t("toasts.configUpdateFailed.title"),
          variant: "error",
          children: (error as Error).message,
          duration: 5000,
        });
        throw error;
      }
    },
    [changeAttributes, createToast, t],
  );

  const handleRename = useCallback(() => {
    setRenameState((prev) => ({
      ...prev,
      renameMode: "rename",
      isRenameModalOpen: true,
    }));
  }, []);

  const handleSaveNew = useCallback(() => {
    setRenameState((prev) => ({
      ...prev,
      renameMode: "saveNew",
      isRenameModalOpen: true,
    }));
  }, []);

  const createPostprocessorCopy = useCallback(async (options: RenameConfigOptions): Promise<PostprocessorDocument> => {
    const requestHeaders = {
      ...options.headers,
      Authorization: options.headers.Authorization,
    };

    return await BackendApi.createPostprocessorDocument({
      headers: requestHeaders,
      body: {
        name: options.name,
        organizationId: options.organizationId,
        originalId: options.originalId,
        ...(options.change && { change: options.change }),
      },
    });
  }, []);

  const updateDocumentInStore = useCallback(
    (updatedDocument: PostprocessorDocument) => {
      useModelsStore
        .getState()
        .setPostprocessorDocuments([
          updatedDocument,
          ...postprocessorDocuments.filter((doc) => doc.id !== updatedDocument.id),
        ]);
      setRenameState((prev) => ({ ...prev, selectedConfig: updatedDocument.name }));
    },
    [postprocessorDocuments],
  );

  const handleSaveNewDocument = useCallback(
    async (newName: string, currentConfig: PostprocessorDocument) => {
      const organizationId = currentConfig.organizationId || project.organizationId;
      if (!organizationId) {
        throw new Error("Organization ID is missing.");
      }

      return await createPostprocessorCopy({
        headers,
        name: newName,
        organizationId,
        originalId: currentConfig.id,
        ...(renameState.renameMode === "saveNew" && {
          change: {
            machineId: currentConfig.machineId,
          },
        }),
      });
    },
    [headers, project.organizationId, renameState.renameMode, createPostprocessorCopy],
  );

  const handleRenameDocument = useCallback(
    async (newName: string, currentConfig: PostprocessorDocument) => {
      return await BackendApi.patchPostprocessorDocumentName({
        params: {
          postprocessorDocumentId: currentConfig.id,
        },
        body: { name: newName },
        headers,
      });
    },
    [headers],
  );

  const handleSubmitRename = useCallback(
    async (newName: string) => {
      setRenameState((prev) => ({ ...prev, isUpdating: true }));

      try {
        if (!currentConfig) {
          throw new Error("No active postprocessor document found.");
        }

        const shouldCreateCopy = renameState.renameMode === "saveNew" || currentConfig.system === true;
        const updatedDocument = shouldCreateCopy
          ? await handleSaveNewDocument(newName, currentConfig)
          : await handleRenameDocument(newName, currentConfig);

        if (!updatedDocument?.id) {
          throw new Error("Invalid response from API");
        }

        updateDocumentInStore(updatedDocument);

        if (shouldCreateCopy) {
          await handleConfigSelection(updatedDocument.id);
        }

        createToast({
          title: shouldCreateCopy ? t("toasts.configCreated.title") : t("toasts.renameSuccess.title"),
          variant: "success",
          duration: 3000,
        });
      } catch (error) {
        createToast({
          title: t("toasts.configUpdateError.title"),
          variant: "error",
          children: (error as Error).message,
          duration: 5000,
        });
        throw error;
      } finally {
        setRenameState((prev) => ({ ...prev, isUpdating: false }));
      }
    },
    [
      currentConfig,
      renameState.renameMode,
      handleSaveNewDocument,
      handleRenameDocument,
      updateDocumentInStore,
      handleConfigSelection,
      createToast,
      t,
    ],
  );

  const updateColorEntry = useCallback(
    async (structureId: string, colorCodeId: number) => {
      if (!currentConfig) {
        throw new Error("No active postprocessor document found.");
      }

      const updatedDocument = await BackendApi.patchPostprocessorDocumentColorEntry({
        params: {
          postprocessorDocumentId: currentConfig.id,
          structureId,
        },
        body: {
          colorCodeId: colorCodeId,
        },
        headers,
      });

      if (updatedDocument) {
        useModelsStore
          .getState()
          .setPostprocessorDocuments([
            updatedDocument,
            ...postprocessorDocuments.filter((doc) => doc.id !== updatedDocument.id),
          ]);
      }

      return updatedDocument;
    },
    [currentConfig, headers, postprocessorDocuments],
  );

  const handleColorChange = useCallback(
    async (knitStructure: KnitStructure, newColorEntryCode: number) => {
      setRenameState((prev) => ({ ...prev, isUpdating: true }));

      try {
        if (!currentConfig) {
          throw new Error("No active postprocessor document found.");
        }

        if (currentConfig.system === true) {
          const organizationId = currentConfig.organizationId || project.organizationId;
          if (!organizationId) {
            throw new Error("Organization ID is missing.");
          }

          const newPostprocessorDocument = await createPostprocessorCopy({
            headers,
            name: `${currentConfig.name}-copy`,
            organizationId,
            originalId: currentConfig.id,
            change: {
              machineId: currentConfig.machineId,
              structureId: knitStructure.id,
              colorCodeId: newColorEntryCode,
            },
          });

          if (newPostprocessorDocument?.id) {
            useModelsStore.getState().setPostprocessorDocuments([newPostprocessorDocument, ...postprocessorDocuments]);
            setRenameState((prev) => ({ ...prev, selectedConfig: newPostprocessorDocument.name }));
            await handleConfigSelection(newPostprocessorDocument.id);
          }

          createToast({
            title: t("toasts.newColorConfigSuccess.title"),
            variant: "success",
            duration: 3000,
          });
        } else {
          await updateColorEntry(knitStructure.id, newColorEntryCode);
        }
      } catch (error) {
        createToast({
          title: t("toasts.colorUpdateError.title"),
          variant: "error",
          children: (error as Error).message,
          duration: 5000,
        });
        throw error;
      } finally {
        setRenameState((prev) => ({ ...prev, isUpdating: false }));
      }
    },
    [
      currentConfig,
      headers,
      project.organizationId,
      postprocessorDocuments,
      createPostprocessorCopy,
      handleConfigSelection,
      updateColorEntry,
      createToast,
      t,
    ],
  );

  const getColorEntryForStructure = useCallback(
    (knitStructure: KnitStructure) => {
      if (!currentConfig || !Array.isArray(currentConfig.entries)) {
        return undefined;
      }
      const entry = currentConfig.entries.find((entry) => entry.knitStructure.id === knitStructure.id);
      return entry?.machineColorEntry;
    },
    [currentConfig],
  );

  return {
    renameState,
    setRenameState,
    currentConfig,
    currentConfigEntries,
    defaultConfig,
    handleConfigSelection,
    handleRename,
    handleSaveNew,
    handleSubmitRename,
    handleColorChange,
    getColorEntryForStructure,
    updateColorEntry,
  };
}
