import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useCurrentProject } from "@fragments/project/container/useCurrentProject";
import { ModelAttributes, Project, Unit } from "@models/backend";
import { Model } from "@models/project";
import { ModelsStore, useModelsStore } from "@state/models";
import { recordToArray, savePathCollection } from "@utils";
import { useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useShallow } from "zustand/react/shallow";

const isValidCategory = (key: string): key is keyof ModelAttributes => {
  return [
    "knitStructure",
    "meshSettings",
    "normals",
    "stitchDensity",
    "sections",
    "columns",
    "bitmapSettings",
    "postprocessorDocument",
  ].includes(key);
};

export const useModelUpdate = ({ modelId }: { projectId?: Project["id"]; modelId: Model["id"] }) => {
  const {
    project: { id: projectId },
  } = useCurrentProject();
  const { headers } = useAuth();
  const { pathCollections, updateModel, updateModelUnit } = useModelsStore(
    ({ updateModel, updateModelUnit, pathCollections }) => ({
      pathCollections,
      updateModel,
      updateModelUnit,
    }),
  );
  const model: Model | undefined = useModelsStore(
    useShallow((state: ModelsStore) => state.models.find(({ id }) => id == modelId)),
  )!;

  const updateBackendAttributes = useCallback(
    async (attributes: ModelAttributes) => {
      await BackendApi.patchProjectModel({
        headers,
        params: { projectId, id: modelId },
        body: { attributes },
      });
    },
    [headers, projectId, modelId],
  );

  const debouncedUpdateBackendAttributes = useDebouncedCallback(updateBackendAttributes, 1000);

  const changeAttributes = useCallback(
    async (changedAttributes: Partial<ModelAttributes>) => {
      const baseAttributes: ModelAttributes = {
        ...(model.attributes ?? {}),
      };

      const newAttributes = Object.entries(changedAttributes).reduce((acc, [key, value]) => {
        if (isValidCategory(key)) {
          return {
            ...acc,
            [key]: value
              ? {
                  ...(acc[key] ?? {}),
                  ...value,
                }
              : null,
          };
        }
        return acc;
      }, baseAttributes);
      console.log({ newAttributes });
      updateModel(modelId, { attributes: newAttributes });
      await debouncedUpdateBackendAttributes(newAttributes);
    },
    [model.attributes, modelId, debouncedUpdateBackendAttributes, updateModel],
  );

  const changeUnit = useCallback(
    async (unit: Unit) => {
      async function updateBackendModelUnit(modelId: Model["id"]) {
        await updateModelUnit(modelId, unit);
        await BackendApi.patchProjectModel({ headers, params: { projectId, id: modelId }, body: { unit } });
        const modelPathCollections = pathCollections[modelId];
        if (modelPathCollections) {
          const collections = recordToArray(modelPathCollections.collections);
          for (const collection of collections) {
            await savePathCollection(headers, projectId, modelId, collection);
          }
        }
      }
      await updateBackendModelUnit(modelId);
      if (model.references) {
        await Promise.all(model.references.map((reference) => updateBackendModelUnit(reference.id)));
      }
    },
    [headers, modelId, model.references, pathCollections, projectId, updateModelUnit],
  );

  return { changeAttributes, changeUnit, model };
};
