import { Avatar, Tooltip, HStack, Center, Text, Box } from "@chakra-ui/react";
import { spacing } from "@design-system";
import { useSemanticTokens } from "@design-system";
import { useState } from "react";
import { UserItem } from "./UserItem";
import { OverflowTooltipContent } from "./OverflowTooltipContent";
import { User } from "@auth";

export type ActiveUserInfo = Pick<User, "id" | "name" | "avatar" | "email">;

export interface ActiveUsersProps {
  users: ActiveUserInfo[];
  maxDisplayed?: number;
  className?: string;
}

export function ActiveUsers({ users: activeUsers, maxDisplayed = 3, className }: ActiveUsersProps) {
  const semanticTokens = useSemanticTokens();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const allUsers = [...activeUsers];
  const additionalUsers = allUsers.length - maxDisplayed;

  return (
    <HStack overflow="visible" spacing={0} className={className}>
      {allUsers.map((user, index) =>
        index < maxDisplayed ? (
          <Box
            key={`${user.email}-${index}`}
            ml={index === 0 ? 0 : "-5px"}
            position="relative"
            zIndex={maxDisplayed - index}
          >
            <Tooltip
              label={<UserItem {...user} />}
              placement="bottom-end"
              hasArrow
              bg={semanticTokens.surface.primary}
              p={0}
            >
              <Avatar
                name={user.name}
                src={user.avatar}
                borderWidth="2px"
                borderColor={semanticTokens.surface.primary}
                width={spacing.space[600]}
                height={spacing.space[600]}
              />
            </Tooltip>
          </Box>
        ) : index === maxDisplayed ? (
          <Box key={`more-users`} ml="-5px" zIndex={0} position="relative">
            <Tooltip
              label={<OverflowTooltipContent users={allUsers} />}
              placement="bottom-end"
              hasArrow
              bg={semanticTokens.surface.primary}
              p={0}
              isOpen={isTooltipOpen}
              onClose={() => setIsTooltipOpen(false)}
            >
              <Center
                width={spacing.space[600]}
                height={spacing.space[600]}
                borderRadius="full"
                borderColor={semanticTokens.surface.primary}
                bg={semanticTokens.surface.invert.accent.secondary}
                color={semanticTokens.text.accent.primary}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                userSelect="none"
                borderWidth="2px"
              >
                <Text variant="3xs-medium">+{additionalUsers}</Text>
              </Center>
            </Tooltip>
          </Box>
        ) : null,
      )}
    </HStack>
  );
}
