import { resolveHexValue } from "@design-system";
import { colors } from "@design-system/colors";
import { Project } from "@models/backend";
import { Model } from "@models/project";
import { useBounds } from "@react-three/drei";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state/project";
import { centerCamera } from "@utils/project/camera";
import { recordToArray } from "@utils/project/collection";
import { sortPathCollections } from "@utils/project/pathCollections";
import { useEffect, useMemo } from "react";
import { Color, MeshPhysicalMaterial } from "three";
import { Part } from "./Part";
import { Path } from "./Path";
import { _3DTools } from "./_3DTools";
import { workspace3dTokens } from "./workspace-3d-tokens";
import { Zone } from "./Zone";

export interface ModelProps {
  project: Project;
  model: Model;
  visible?: boolean;
}

function ModelViewer({ project, model, visible }: ModelProps) {
  const { mesh, references } = model;
  const bounds = useBounds();
  const { pathCollections: pathCollectionsSlice } = useModelsStore((s) => s);
  const { collections } = pathCollectionsSlice[model.id] ?? { collections: {}, newCollection: null };

  const knitStructures = useModelsStore((state) => state.knitStructures);
  const selectedKnitStructure = useMemo(() => {
    const modelKnitStructure = model.attributes?.knitStructure;
    const defaultKnitStructure = knitStructures.find(({ isDefault }) => isDefault);
    return modelKnitStructure ?? defaultKnitStructure;
  }, [model, knitStructures]);

  const { _3DToolbarSelection } = useProjectState();
  const pathCollections = sortPathCollections(recordToArray(collections), model["zoneOrder"]);
  const pathsViz = pathCollections
    .map((collection) => (
      <Path
        key={collection.id}
        project={project}
        model={model}
        collectionId={collection.id}
        collectionUsage={collection.usage}
        isSelectionAllowed={_3DToolbarSelection === "select"}
      />
    ))
    .flat();
  const zonesViz = pathCollections
    .filter(({ usage, id }) => id && usage === "zone")
    .map(({ id }, index) => <Zone key={id} model={model} index={index} pathCollectionId={id!} />);

  useEffect(() => {
    centerCamera(mesh, bounds);
  }, [mesh, bounds]);

  const meshMaterial = useMemo(() => {
    return new MeshPhysicalMaterial({
      color: new Color(selectedKnitStructure?.color ?? colors.gray["200"]),
      ...workspace3dTokens.material,
    });
  }, [selectedKnitStructure?.color]);

  return (
    <group>
      {references.map((reference) => (
        <mesh
          key={reference.id}
          name={reference.name}
          geometry={reference.mesh.geometry}
          material={
            new MeshPhysicalMaterial({
              color: new Color(resolveHexValue(reference.attributes.meshSettings?.color ?? colors.gray["200"])),
              ...workspace3dTokens.material,
            })
          }
          renderOrder={-1}
        />
      ))}
      <group visible={visible}>
        {mesh && <mesh name="model" geometry={mesh.geometry} material={meshMaterial} />}
        {<_3DTools project={project} model={model} />}
        {pathsViz}
        {zonesViz}
        <Part project={project} model={model} />
      </group>
    </group>
  );
}

export default ModelViewer;
