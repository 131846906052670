import { Portal as ChakraPortal, Box } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { ReactNode } from "react";
import { utility } from "@design-system";

export type PortalProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  width?: string | number;
  closeOnOverlayClick?: boolean;
  zIndex?: number;
  useDefaultPosition?: boolean;
} & StyleProps;

export function Portal({
  children,
  isOpen,
  onClose = () => {},
  width = "450px",
  closeOnOverlayClick = true,
  zIndex = 1000,
  useDefaultPosition = true,
  ...styles
}: PortalProps) {
  if (!isOpen) return null;

  return (
    <ChakraPortal>
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg={utility.secondary.black}
        opacity={0.4}
        zIndex={zIndex}
        onClick={closeOnOverlayClick ? onClose : undefined}
      />
      <Box
        position="fixed"
        {...(useDefaultPosition
          ? {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }
          : {})}
        width={width}
        zIndex={zIndex + 1}
        onClick={(e) => e.stopPropagation()}
        boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 10px 36px 0px rgba(0, 0, 0, 0.50)"
        {...styles}
      >
        {children}
      </Box>
    </ChakraPortal>
  );
}
