import { workspace3dTokens } from "./workspace-3d-tokens";

const shoulderLight = workspace3dTokens.lighting.shoulder;
const groundLight = workspace3dTokens.lighting.ground;
const hemisphereLight = workspace3dTokens.lighting.hemisphere;
const zOffset = workspace3dTokens.lighting.zOffset;

export function StudioLights() {
  return (
    <>
      <directionalLight
        position={[-shoulderLight.xOffset, shoulderLight.yOffset, zOffset]}
        intensity={shoulderLight.intensity}
        color={shoulderLight.color}
      />
      <directionalLight
        position={[shoulderLight.xOffset, shoulderLight.yOffset, zOffset]}
        intensity={shoulderLight.intensity}
        color={shoulderLight.color}
      />
      <directionalLight
        position={[groundLight.xOffset, groundLight.yOffset, zOffset]}
        intensity={groundLight.intensity}
        color={groundLight.color}
      />
      <hemisphereLight
        intensity={hemisphereLight.intensity}
        color={shoulderLight.color}
        groundColor={groundLight.color}
      />
    </>
  );
}
