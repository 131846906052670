import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { useToast } from "@design-system";
import { useTranslation } from "@hooks";
import { Model, PathCollection as PathCollectionPayload } from "@models/backend";
import { PathCollection } from "@models/project";
import { useCallback } from "react";

function toBackendPathCollection(modelId: Model["id"], pathCollection: PathCollection) {
  const { id, usage, name, attributes, points, type = "Path", isLoop, controlVectors } = pathCollection;

  return {
    id,
    name,
    modelId,
    usage,
    attributes,
    paths: [
      {
        id,
        type,
        controlVectors,
        points,
        isLoop,
      },
    ],
  } as PathCollectionPayload;
}

export function useBackendPathCollection() {
  const { headers } = useAuth();
  const { t } = useTranslation("hooks.pathCollections");
  const toast = useToast();

  const createBackendPathCollection = useCallback(
    async (projectId: string, modelId: string, pathCollection: Omit<PathCollection, "id">) => {
      const { usage = null, name, attributes, points, type = "Path", isLoop, controlVectors } = pathCollection;
      const request = {
        headers,
        params: {
          projectId,
          modelId,
        },
        body: {
          name: name ?? "Collection",
          modelId,
          usage,
          attributes,
          paths: [
            {
              type,
              controlVectors,
              points,
              isLoop,
            },
          ],
        },
      };

      try {
        return await BackendApi.createPathCollection(request);
      } catch (error) {
        console.log({ message: "Failed to upload path", error, project: request.body });
        toast({
          title: t("create.toast.status.error.title"),
          children: t("create.toast.status.error.description"),
          variant: "error",
        });
        throw error;
      }
    },
    [headers, t, toast],
  );

  const updateBackendPathCollection = useCallback(
    async (projectId: string, modelId: string, pathCollection: PathCollection) => {
      if (pathCollection.id === undefined) {
        return null;
      }

      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: pathCollection.id,
        },
        body: toBackendPathCollection(modelId, pathCollection),
      };

      try {
        await BackendApi.updatePathCollection(request);
      } catch (error) {
        console.log({ message: "Failed to update path", error, project: request.body });
        toast({
          title: t("update.toast.status.error.title"),
          children: t("update.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  const updateBackendPathCollections = useCallback(
    async (projectId: string, modelId: string, pathCollections: PathCollection[]) => {
      const body = pathCollections.map((collection) => {
        return toBackendPathCollection(modelId, collection);
      });

      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
        },
        body,
      };

      try {
        await BackendApi.updatePathCollections(request);
      } catch (error) {
        console.log({ message: "Failed to update path", error, project: request.body });
        toast({
          title: t("update.toast.status.error.title"),
          children: t("update.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  const deleteBackendPathCollection = useCallback(
    async (projectId: string, modelId: string, pathCollectionId: string) => {
      const request = {
        headers,
        params: {
          projectId: projectId,
          modelId: modelId,
          id: pathCollectionId,
        },
      };

      try {
        await BackendApi.deletePathCollection(request);
      } catch (error) {
        console.log({ message: "Failed to delete path", error, request });
        toast({
          title: t("delete.toast.status.error.title"),
          children: t("delete.toast.status.error.description"),
          variant: "error",
        });
      }
    },
    [headers, t, toast],
  );

  return {
    createBackendPathCollection,
    updateBackendPathCollection,
    updateBackendPathCollections,
    deleteBackendPathCollection,
  };
}
