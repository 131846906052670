import { Project } from "@models/backend";
import { useMemo } from "react";
import { MeshBasicMaterial, MeshBasicMaterialParameters, MultiplyBlending } from "three";
import { Process } from "@models/project";

export interface KnitMeshProps {
  project: Project;
  process: Process;
}

function KnitMeshViewer({ process: { mesh } }: KnitMeshProps) {
  const materialWireframe = useMemo(() => {
    const materialWireframe = new MeshBasicMaterial({ ...mesh.material } as MeshBasicMaterialParameters);
    materialWireframe.wireframe = true;
    materialWireframe.transparent = true;
    materialWireframe.opacity = 0.75;
    materialWireframe.blending = MultiplyBlending;
    return materialWireframe;
  }, [mesh]);

  return (
    <group>
      {mesh && <mesh name="model" geometry={mesh.geometry} material={mesh.material} />}
      {mesh && <mesh name="model" geometry={mesh.geometry} material={materialWireframe} />}
    </group>
  );
}

export default KnitMeshViewer;
