import { useState, useEffect, useRef } from "react";
import { supabase } from "@/supabase/client";
import { User } from "@auth";
import { RealtimePresenceState } from "@supabase/supabase-js";

export type PresencePayload = {
  id: string;
  email: string;
  avatar: string;
  name: string;
};

export type PresenceState = Record<string, PresencePayload[]>;

export interface GlobalPresenceUser extends PresenceUser {
  projectId: string | null;
}

export type GlobalPresenceState = RealtimePresenceState<GlobalPresenceUser>;

export interface PresenceUser {
  id: string;
  email: string;
  avatar: string;
  name: string;
  projectId?: string | null;
}

export function useGlobalPresence(user: User, currentProjectId: string | null) {
  const [globalPresenceState, setGlobalPresenceState] = useState<GlobalPresenceState>({});
  const channelRef = useRef<ReturnType<typeof supabase.channel> | null>(null);

  useEffect(() => {
    if (!user) return;

    const channel = supabase.channel("global-presence", {
      config: { presence: { key: user.id } },
    });
    channelRef.current = channel;

    channel.on("presence", { event: "sync" }, () => {
      const newState = channel.presenceState() as GlobalPresenceState;
      setGlobalPresenceState((prevState) => {
        const mergedState = { ...prevState, ...newState };
        Object.keys(mergedState).forEach((key) => {
          if (!(key in newState)) {
            delete mergedState[key];
          }
        });
        return mergedState;
      });
    });

    const updatePresenceState = () => {
      const updatedState = channel.presenceState() as GlobalPresenceState;
      setGlobalPresenceState(updatedState);
    };

    channel.on("presence", { event: "join" }, updatePresenceState);
    channel.on("presence", { event: "leave" }, updatePresenceState);

    const subscribeChannel = async () => {
      try {
        await channel.subscribe(async (status) => {
          if (status === "SUBSCRIBED" && user) {
            const payload = {
              email: user.email,
              avatar: user.avatar,
              name: user.name,
              id: user.id,
              projectId: currentProjectId,
            };
            await channel.track(payload);
          } else if (status === "TIMED_OUT") {
            subscribeChannel();
          } else if (status === "CLOSED") {
            setTimeout(() => {
              subscribeChannel();
            }, 1000);
          } else if (status === "CHANNEL_ERROR") {
            setTimeout(() => {
              subscribeChannel();
            }, 2000);
          }
        });
      } catch (error) {
        console.error("Subscription error:", error);
      }
    };

    subscribeChannel();

    return () => {
      if (channelRef.current) {
        channelRef.current.unsubscribe();
        channelRef.current = null;
      }
    };
  }, [user, currentProjectId]);

  useEffect(() => {
    const channel = channelRef.current;
    if (channel?.state === "joined") {
      channel.track({
        email: user.email,
        avatar: user.avatar,
        name: user.name,
        id: user.id,
        projectId: currentProjectId,
      });
    }
  }, [currentProjectId, user]);

  return { globalPresenceState };
}
