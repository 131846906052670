import { Moving } from "@fragments/project/workspace-3d/bezHandlesTypes";
import { usePath, usePointerState } from "@hooks";
import { useCustomCursor } from "@hooks/useCustomCursor";
import { Project } from "@models/backend";
import { Model } from "@models/project";
import { useThree } from "@react-three/fiber";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state/project";
import { vector3ToScreenSpace } from "@utils/project/raycaster";
import { Vector3 as VP } from "@variant-tech/pattern-derivation";
import { useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Vector3 } from "three";
import { BezHandles } from "./BezHandles";
import { ClickTarget } from "./ClickTarget";
import { Path } from "./Path";
import { workspace3dTokens } from "./workspace-3d-tokens";

type PenToolProps = {
  project: Project;
  model: Model;
};

// NOTE: this operates more like a PENCIL tool due to useFrame
export function PenTool({ project, model }: PenToolProps) {
  const { mesh } = model;
  const { pathCollections } = useModelsStore();
  const { appendPoint, changeVector, pushPath, getNormalAndTangent, removePoints, pathCollection, removePath } =
    usePath(project, model, pathCollections[model.id].newCollection);

  const [checkVector, setChecking] = useState<{ index: number; point: VP } | null>(null);

  const { setCursor } = useCustomCursor();
  const [hovered, setHovered] = useState<number | undefined>(undefined);

  const { set3DToolbarSelection } = useProjectState();

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const DRAG_TIMEOUT = 100;

  useHotkeys("enter", () => push());
  useHotkeys("escape", () => abort());
  useHotkeys("shift+enter", () => push(true));

  const push = (close = false) => {
    if (pathCollection.points.length > 1) {
      const minPoints = pathCollection.type === "BezierPath" ? 2 : 3;
      const canClose = pathCollection.points.length >= minPoints;

      pushPath(close && canClose);
      setHovered(undefined);
      setCursor("pen");
    }
  };

  const abort = async () => {
    if (pathCollection.points.length > 0) {
      await removePath();
    } else {
      setHovered(undefined);
      set3DToolbarSelection("select");
    }
  };

  // state is too slow for useFrame
  const { raycaster, camera, size } = useThree();
  const { pointer0Down, altKey } = usePointerState();
  const { points, controlVectors /*, curves */ } = pathCollection;
  const minDistance = workspace3dTokens.penTool.minDistance;

  useEffect(() => {
    if (altKey) {
      setCursor("remove");
    } else {
      if (hovered !== undefined && hovered !== points.length - 1) {
        setCursor("coincident");
      } else {
        setCursor("pen");
      }
    }
  }, [altKey, hovered, points]);

  const process = async () => {
    if (!pointer0Down || altKey) {
      return;
    }
    const hit = raycaster.intersectObject(mesh)[0];
    if (!hit) {
      return;
    }

    const hit2D = vector3ToScreenSpace(new Vector3(...hit.point), camera, size);
    const points2D = points.map((point) => {
      return vector3ToScreenSpace(new Vector3(...point), camera, size);
    });

    if (points2D.length && hit2D.distanceTo(points2D.slice(-1)[0]) < minDistance) {
      return;
    }

    let pos: [number, number, number] = [hit.point.x, hit.point.y, hit.point.z];

    let close = false;

    // snap to existing point
    if (points2D.filter((pt) => pt.distanceTo(hit2D) < minDistance).length > 0) {
      const point = points2D.filter((pt) => pt.distanceTo(hit2D) < minDistance)[0];
      const pointIdx = points2D.indexOf(point);
      pos = points[pointIdx];

      if (pointIdx === 0) {
        close = true;
      }
    }

    if (close) {
      push(true);
      return;
    }

    const index = points.length;
    await appendPoint(pos);
    if (timeout.current) {
      setChecking({ point: pos, index });
    } else {
      setChecking(null);
    }
  };

  async function removePoint(index: number) {
    await removePoints([index]);
  }

  const clickTargets = points.map((sp, index) => {
    return (
      <ClickTarget
        scale={workspace3dTokens.surfacePoint.outerRadius * 2}
        tolerance={0}
        position={new Vector3(...sp)}
        onClick={() => {
          if (altKey) {
            removePoint(index);
            setHovered(undefined);
          } else {
            if (index == points.length - 1) {
              push();
            }
          }
        }}
        onHoveredChange={(hovered: boolean) => {
          if (hovered) {
            if (!altKey && index !== points.length - 1) {
              setCursor("coincident");
            }
            setHovered(index);
          } else {
            setCursor(altKey ? "remove" : "pen");
            setHovered(undefined);
          }
        }}
      />
    );
  });

  useEffect(() => {
    process();

    if (!pointer0Down) {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
        setChecking(null);
      }
    } else {
      if (!timeout.current) {
        timeout.current = setTimeout(() => {
          console.log("dragging");
        }, DRAG_TIMEOUT);
      }
    }
  }, [pointer0Down, altKey]);

  return (
    <>
      {clickTargets}
      <Path project={project} model={model} />
      {checkVector && (
        <BezHandles
          point={checkVector.point}
          controlVectors={
            controlVectors?.[checkVector.index] ?? [
              [0, 0, 0],
              [0, 0, 0],
            ]
          }
          getNormalAndTangent={async () => await getNormalAndTangent(checkVector.index)}
          mesh={model.mesh}
          changeVector={async (vector?: VP) => {
            setChecking(null);
            if (vector) {
              await changeVector(checkVector.index, vector);
            }
          }}
          initialMoving={Moving.VECTOR_RIGHT}
        />
      )}
    </>
  );
}
