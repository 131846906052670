import { useProgressToast, useTranslation } from "@hooks";
import { useModelLoader, usePathCollectionLoader } from "@hooks/project";
import { KnitStructure, Model as BackendModel, Project } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { useModelsStore } from "@state";
import { computeStitchDensity } from "@utils/project/knitProcess.ts";
import { getMeshPayload } from "@utils/project/mesh.ts";
import { omit } from "lodash";
import { useEffect } from "react";

type UseSavedModelProps = {
  triggerUploadModelModal: () => void;
  project: Project;
};

export function useLoadProject({ project, triggerUploadModelModal }: UseSavedModelProps) {
  const {
    knitStructures,
    clear: clearModels,
    setColumnCurves,
    setSectionCurves,
  } = useModelsStore(({ knitStructures, clear, setColumnCurves, setSectionCurves }) => ({
    knitStructures,
    clear,
    setColumnCurves,
    setSectionCurves,
  }));
  const { t } = useTranslation("hooks.models.load.progress");
  const toast = useProgressToast();
  const { loadModel } = useModelLoader();
  const { loadPathCollections } = usePathCollectionLoader();

  async function loadCollections(model: Model, backendModel: BackendModel) {
    try {
      return await loadPathCollections(model, backendModel);
    } catch (error) {
      console.warn("Error loading path collections:", error);
      return [];
    }
  }

  async function computeGridCurves(
    model: Model,
    backendModel: BackendModel,
    guideSource: PathCollection,
    zones: PathCollection[],
    stitchDensity: { course: number; wale: number },
  ) {
    const { columnCurves, sectionCurves } = await getMeshPayload(
      model,
      guideSource,
      zones,
      backendModel.columnAnchors.map(({ point }) => point).filter((p) => p.length === 3),
      backendModel.sectionAnchors.map(({ point }) => point).filter((p) => p.length === 3),
      stitchDensity,
    );

    return {
      columnCurves: columnCurves.map((curve, index) => ({
        ...omit(backendModel.columnAnchors[index], "point"),
        ...curve,
        name: `Column ${index + 1}`,
      })),
      sectionCurves: sectionCurves.map((curve, index) => ({
        ...omit(backendModel.sectionAnchors[index], "point"),
        ...curve,
        name: `Section ${index + 1}`,
      })),
    };
  }

  async function loadGridCurves(
    backendModel: BackendModel,
    model: Model,
    pathCollections: PathCollection[],
    defaultKnitStructure?: KnitStructure,
  ) {
    const guideSource = pathCollections?.find(({ usage }) => usage === "guide");

    if (!guideSource) {
      return undefined;
    }

    const zones = pathCollections.filter((path) => path.usage === "zone");
    const stitchDensity = computeStitchDensity(model, defaultKnitStructure);
    const gridCurves = await computeGridCurves(model, backendModel, guideSource, zones, stitchDensity);

    if (gridCurves) {
      setColumnCurves(model.id, gridCurves.columnCurves);
      setSectionCurves(model.id, gridCurves.sectionCurves);
    }

    return gridCurves;
  }

  const loadProject = async (project: Project) => {
    clearModels();
    const defaultKnitStructure = knitStructures.find(({ isDefault }) => isDefault);

    for (const backendModel of project.models) {
      const { update } = toast(t, { title: backendModel.name, status: "in-progress" });

      try {
        const model = await loadModel(backendModel);

        update({
          message: t(`status.in-progress.path-collections`),
          status: "in-progress",
        });

        if (!backendModel.parentId) {
          const pathCollections = await loadCollections(model, backendModel);

          if (!pathCollections) {
            update({
              status: "error",
              message: t("status.error.pathCollections"),
            });

            continue;
          }

          try {
            await loadGridCurves(backendModel, model, pathCollections, defaultKnitStructure);
            update({ status: "success" });
          } catch (e) {
            console.warn("Error loading grid curves:", e);
            update({
              status: "error",
              message: t("status.error.gridCurves"),
            });
          }
        }
      } catch (error) {
        console.error("Error loading backend model", backendModel, error);

        update({
          status: "error",
          message: t("status.error.loadFailed"),
        });

        if (!backendModel.parentId && triggerUploadModelModal) {
          triggerUploadModelModal();
        }
      }
    }

    if (!project.models?.length && triggerUploadModelModal) {
      triggerUploadModelModal();
    }
  };

  useEffect(() => {
    loadProject(project);
  }, [project]);
}
