import { IconButton, StyleProps, SystemStyleObject, Tooltip, TooltipProps, useStyleConfig } from "@chakra-ui/react";
import { useSemanticTokens } from "@design-system";
import { ToggleButtonStyleVariant } from "@design-system/component-styles/ToggleButtonStyleConfig.ts";
import { ReactElement } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export type ToggleIconButtonProps = {
  icon: ReactElement;
  label: string;
  isSelected: boolean;
  onToggle: () => void;
  shortcut?: {
    label: string;
    keys: string | string[];
  };
  disabled?: boolean;
  tooltipDisabled?: boolean;
  variant?: ToggleButtonStyleVariant;
  size?: "sm" | "md" | "lg";
} & Pick<TooltipProps, "placement"> &
  Omit<StyleProps, "onClick">;

export function ToggleIconButton({
  isSelected,
  onToggle,
  placement = "right",
  variant,
  shortcut,
  label,
  disabled,
  tooltipDisabled,
  size,
  ...iconProps
}: ToggleIconButtonProps) {
  const style = useStyleConfig("ToggleIconButton", { variant, size }) as { _active: SystemStyleObject };

  useHotkeys(shortcut?.keys || "", onToggle, { preventDefault: true });

  return (
    <Tooltip
      isDisabled={tooltipDisabled ?? false}
      hasArrow
      placement={placement}
      openDelay={500}
      label={label + (shortcut ? ` (${shortcut.keys})` : "")}
      shadow="lg"
      rounded="lg"
      bg={useSemanticTokens().surface.classic.primary}
    >
      <IconButton
        isDisabled={disabled ?? false}
        aria-label={label}
        __css={isSelected ? { ...style, ...(style?._active ?? {}) } : style}
        onClick={() => onToggle()}
        {...iconProps}
      />
    </Tooltip>
  );
}
