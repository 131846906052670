import { useContext } from "react";
import { ProjectContext, ProjectContextProps } from "./projectContext";

export function useCurrentProject(): Required<ProjectContextProps> {
  const { project, v3dApi } = useContext(ProjectContext);

  if (!project || !v3dApi) {
    throw new Error("useCurrentProject must be used inside a CurrentProjectProvider");
  }

  return { project, v3dApi };
}
