import { colors } from "@design-system/colors";
import { Model } from "@models/project";
import { useModelsStore } from "@state/models";
import { computeZoneKnitStructure } from "@utils/project/zone";
import { Zone as V3dZone } from "@variant-tech/pattern-derivation";
import { useEffect, useState } from "react";
import { BufferAttribute, BufferGeometry, MeshPhysicalMaterial } from "three";
import { workspace3dTokens } from "./workspace-3d-tokens";

export interface ZoneProps {
  model: Model;
  pathCollectionId: string;
  index: number;
}

export function Zone({ model, index, pathCollectionId }: ZoneProps) {
  const knitStructures = useModelsStore((state) => state.knitStructures);
  const pathCollection = useModelsStore(
    ({ pathCollections }) => pathCollections?.[model.id]?.collections[pathCollectionId],
  );
  const defaultKnitStructure = knitStructures.find(({ isDefault }) => isDefault);
  const selectedKnitStructure = computeZoneKnitStructure(pathCollection, model) ?? defaultKnitStructure;
  const [geometry, setGeometry] = useState<BufferGeometry>();
  const [material, setMaterial] = useState<MeshPhysicalMaterial>();

  useEffect(() => {
    if (pathCollection?.usage !== "zone") {
      setGeometry(undefined);
      return;
    }

    const { faces, vertices } = pathCollection as V3dZone;

    if (!faces || !vertices) {
      setGeometry(undefined);
      return;
    }

    const faceIndices = Array.from(faces);
    const geometry = new BufferGeometry();

    geometry.setIndex(faceIndices);
    geometry.setAttribute("position", new BufferAttribute(vertices, 3));
    geometry.computeVertexNormals();

    setGeometry(geometry);
  }, [pathCollection?.usage, pathCollection?.vertices]);

  useEffect(() => {
    setMaterial(
      new MeshPhysicalMaterial({
        color: selectedKnitStructure?.color ?? colors.gray["200"],
        polygonOffset: true,
        polygonOffsetUnits: 1,
        polygonOffsetFactor: -1 - index,
        ...workspace3dTokens.material,
      }),
    );
  }, [selectedKnitStructure]);

  return geometry && material && <mesh geometry={geometry} material={material} renderOrder={index} />;
}
