import { useCalibrationCurves, usePointerState } from "@hooks";
import { useGridCurvePreview } from "@hooks/project/useGridCurvePreview.tsx";
import { useCustomCursor } from "@hooks/useCustomCursor";
import { Project, SectionAnchorAttributes } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { useProjectState } from "@state/project";
import { GridCurve as ICurve } from "@variant-tech/pattern-derivation";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Curve } from "./curve";
import { workspace3dTokens } from "./workspace-3d-tokens";

type CalibrationCurveToolProps = {
  project: Project;
  model: Model;
  guideSource: PathCollection;
  pathCollections: PathCollection[];
};

export function CalibrationCurveTool({ project, model, guideSource, pathCollections }: CalibrationCurveToolProps) {
  const { setCursor } = useCustomCursor();
  const { _3DToolbarSelection: mode, set3DToolbarSelection: setMode } = useProjectState();
  const { computeColumnCurve, computeSectionCurve } = useCalibrationCurves({ project, model });
  const [calibrationCurve, setCalibrationCurve] = useState<ICurve | null>(null);

  useHotkeys("escape", () => setMode("select"));
  useEffect(() => setCursor("addSelection"), []);
  useGridCurvePreview({
    guideSource,
    mode,
    model,
    setPreviewGridCurve: setCalibrationCurve,
    pathCollections,
  });

  const { pointer0Down } = usePointerState();

  useEffect(() => {
    if (!pointer0Down || !calibrationCurve) {
      return;
    }

    const attributes: SectionAnchorAttributes = { source: "user" };

    switch (mode) {
      case "section":
        computeSectionCurve(guideSource, { ...calibrationCurve, attributes }).catch(console.warn);
        break;
      case "column":
        computeColumnCurve(guideSource, { ...calibrationCurve, attributes }).catch(console.warn);
        break;
    }
  }, [pointer0Down]);

  if (!calibrationCurve) {
    return null;
  }

  const calibrationCurveStyle = mode === "section" ? workspace3dTokens.path.section : workspace3dTokens.path.column;

  return calibrationCurve.segments.map(({ vertices }, index) => (
    <Curve
      key={index}
      mesh={model.mesh}
      curve={vertices}
      color={calibrationCurveStyle.color}
      opacity={1.0}
      width={2.0}
      showLengthOverride
    />
  ));
}
