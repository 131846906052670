import { useCallback, useEffect } from "react";
import BackendApi from "@api/BackendApi";
import { useAuth } from "@auth";
import { loadKnitMesh } from "@utils/project/mesh";
import { useModelsStore } from "@state/models";
import { Process as BackendProcess } from "@models/backend";

interface useProcessLoaderProps {
  projectId: string;
}

export function useProcessLoader({ projectId }: useProcessLoaderProps) {
  const { headers } = useAuth();
  const setProcess = useModelsStore(({ setProcess }) => setProcess);

  useEffect(() => {
    loadLastProcess(projectId);
  }, [projectId]);

  const getLastProcess = useCallback(
    async (projectId: string) => {
      return (
        await BackendApi.getProcesses({
          headers,
          params: { projectId },
        })
      )[0];
    },
    [headers],
  );

  const setKnitMesh = useCallback(
    async (process: BackendProcess) => {
      console.log("process", process);
      if (!process.output?.objUrl) {
        throw new Error("Process does not have an obj file");
      }
      const mesh = await loadKnitMesh(process.output.objUrl, process.output.bmpUrl);

      setProcess({
        mesh,
        ...process,
      });
    },
    [setProcess],
  );

  const loadLastProcess = useCallback(
    async (projectId: string) => {
      const process = await getLastProcess(projectId);
      if (process != null) {
        try {
          await setKnitMesh(process);
        } catch (error) {
          console.error("Failed to set knit mesh:", error);
        }
      }
    },
    [setKnitMesh, getLastProcess],
  );
  return { loadLastProcess, setKnitMesh };
}
