import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Portal } from "@design-system/components/Portal";
import { DataGrid } from "@design-system/components/DataGrid";
import { spacing, useSemanticTokens } from "@design-system";
import { useTranslation } from "@hooks";
import { ColumnDef } from "@tanstack/react-table";
import { LockIcon } from "@chakra-ui/icons";
import { PostprocessorConfigProps } from "./PostprocessorConfigTypes";
import { usePostprocessorConfig } from "@hooks";
import { ConfigSelector } from "./ConfigSelector";
import { ConfigActions } from "./ConfigActions";
import { RenamePanel } from "./RenamePanel";
import { StructureRow } from "./StructureRow";
import { KnitStructure, MachineColorEntry } from "@models/backend";
import { CloseIcon } from "@chakra-ui/icons";

export function PostprocessorConfig({
  isOpen,
  onClose,
  machines,
  project,
  model,
  postprocessorDocuments,
}: PostprocessorConfigProps) {
  const semanticTokens = useSemanticTokens();
  const { t } = useTranslation("projectProperties.postprocessorConfig");

  const {
    currentConfig,
    currentConfigEntries,
    defaultConfig,
    renameState,
    setRenameState,
    handleConfigSelection,
    handleRename,
    handleSaveNew,
    handleSubmitRename,
    handleColorChange,
    getColorEntryForStructure: getConfigColorEntry,
  } = usePostprocessorConfig({
    model,
    postprocessorDocuments,
    project,
  });

  const colorEntryOptions = machines[0]?.colorEntries.map((entry) => entry) || [];

  const columns: ColumnDef<KnitStructure>[] = [
    {
      id: "structure",
      size: 279,
      header: () => (
        <HStack spacing={spacing.space["100"]}>
          <LockIcon color={semanticTokens.text.secondary} boxSize="12px" />
          <Text color={semanticTokens.text.secondary} variant="2xs-regular">
            {t("structures.label")}
          </Text>
        </HStack>
      ),
    },
    {
      id: "arrow",
      size: 40,
    },
    {
      id: "color",
      size: 84,
      header: () => (
        <Text color={semanticTokens.text.secondary} variant="2xs-regular" whiteSpace="nowrap">
          {t("bitmapColor.label")}
        </Text>
      ),
    },
  ];

  const getColorEntryForStructure = (knitStructure: KnitStructure): MachineColorEntry => {
    const configEntry = getConfigColorEntry(knitStructure);
    if (configEntry) {
      return configEntry;
    }
    return { ...colorEntryOptions[0], machineId: machines[0].id };
  };

  return (
    <Portal
      isOpen={isOpen}
      onClose={onClose}
      width="450px"
      useDefaultPosition={false}
      position="fixed"
      top="48px"
      right="244px"
      bg={semanticTokens.surface.primary}
      border={`1px solid ${semanticTokens.border.classic.primary}`}
      boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 10px 36px 0px rgba(0, 0, 0, 0.50)"
      maxHeight="calc(100vh - 96px)"
    >
      <HStack
        height="40px"
        px={spacing.space["300"]}
        borderBottom={`1px solid ${semanticTokens.border.classic.primary}`}
        justify="space-between"
        align="center"
        bg={semanticTokens.surface.primary}
      >
        <Text color={semanticTokens.text.primary} variant="2xs-header">
          {t("label")}
        </Text>
        <Button
          variant="ghost"
          size="sm"
          onClick={onClose}
          p={0}
          minW="auto"
          height="auto"
          color={semanticTokens.text.primary}
          _hover={{ bg: semanticTokens.surface.classic }}
        >
          <CloseIcon boxSize={3} />
        </Button>
      </HStack>

      <VStack spacing="0" align="stretch" p={spacing.space["300"]} bg={semanticTokens.surface.primary}>
        <Text color={semanticTokens.text.primary} variant="2xs-regular">
          {t("postprocessor.label")}
        </Text>

        <Box pb={spacing.space["200"]}>
          <Box position="sticky" top={0} zIndex={1} bg={semanticTokens.surface.secondary}>
            <StructureRow isHeader />
          </Box>

          <HStack spacing={spacing.space["200"]} height="40px">
            <ConfigSelector
              selectedConfig={currentConfig ?? defaultConfig}
              postprocessorDocuments={postprocessorDocuments}
              onSelect={handleConfigSelection}
              isDisabled={renameState.isUpdating}
            />
            <ConfigActions onRename={handleRename} onSaveNew={handleSaveNew} isDisabled={renameState.isUpdating} />
          </HStack>
        </Box>

        <Box
          position="relative"
          border={`1px solid ${semanticTokens.border.classic.primary}`}
          borderBottom="none"
          bg={semanticTokens.surface.secondary}
          maxHeight="calc(100vh - 280px)"
          overflowY="auto"
          sx={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box position="relative">
            <Box
              position="absolute"
              left="279px"
              top={0}
              bottom={0}
              width="1px"
              bg={semanticTokens.border.classic.primary}
              zIndex={1}
              height="100%"
            />
            <Box
              position="absolute"
              left="319px"
              top={0}
              bottom={0}
              width="1px"
              bg={semanticTokens.border.classic.primary}
              zIndex={1}
              height="100%"
            />

            <DataGrid
              columns={columns}
              data={currentConfigEntries || []}
              render={(row) => (
                <StructureRow
                  row={row}
                  colorEntry={getColorEntryForStructure(row.original)}
                  machine={machines[0]}
                  colorEntryOptions={machines[0].colorEntries.map((entry) => ({
                    ...entry,
                    machineId: machines[0].id,
                  }))}
                  onColorChange={handleColorChange}
                  isDisabled={renameState.isUpdating}
                />
              )}
              spacing={0}
            />
          </Box>
        </Box>
      </VStack>

      <RenamePanel
        isOpen={renameState.isRenameModalOpen}
        onClose={() => setRenameState((prev) => ({ ...prev, isRenameModalOpen: false }))}
        currentName={currentConfig?.name ?? defaultConfig?.name ?? "DEFAULT"}
        onSubmit={handleSubmitRename}
        mode={renameState.renameMode}
        isSubmitting={renameState.isUpdating}
      />
    </Portal>
  );
}

export default PostprocessorConfig;
