import "@/globals";
import { useCalibrationCurves, useTranslation } from "@hooks";
import { useBackendPathCollection } from "@hooks/project/useBackendPathCollection";
import { Project } from "@models/backend";
import { Model, PathCollection, PathCollectionInput } from "@models/project";
import { useModelsStore } from "@state/models";
import { PathAction, PathActionType } from "@state/models/reducers/pathReducer";
import { getV3dApi } from "@utils/project/initV3dApi.ts";

import { Curve, PathInput, Vector3, Zone, ZoneInput } from "@variant-tech/pattern-derivation";
import { pick } from "lodash";
import { useCallback, useMemo } from "react";

export function usePath(project: Project, model: Model, pathCollection: PathCollectionInput | PathCollection) {
  const {
    changePathCollection,
    changePathCollectionNew,
    clearNewPathCollection,
    pushPathCollection,
    dispatchPathNew,
    dispatchPathEdit,
    removePathCollection,
    pathCollections,
  } = useModelsStore();

  const { createBackendPathCollection, updateBackendPathCollections, deleteBackendPathCollection } =
    useBackendPathCollection();

  const { generateGridCurves } = useCalibrationCurves({ project, model });

  const { t } = useTranslation("project.sidebar.layers");

  const noGuide = useMemo(() => {
    const collections = pathCollections[model.id].collections;
    for (const id in collections) {
      if (collections[id].usage === "guide") {
        return false;
      }
    }
    return true;
  }, [pathCollections]);

  const dispatch = useCallback(
    (action: PathAction) => {
      if ("id" in pathCollection) {
        return dispatchPathEdit(model.id, pathCollection.id, action);
      } else {
        return dispatchPathNew(model.id, action);
      }
    },
    [model, pathCollection, dispatchPathNew, dispatchPathEdit],
  );

  const updateAfterPathChange = useCallback(
    async (pathCollection: PathCollection[]) => {
      const guideCurve = pathCollection.find((collection) => collection.usage === "guide");

      if (guideCurve) {
        const numAutoColumns = model.attributes.columns?.count || 0;
        const numAutoSections = model.attributes.sections?.count || 0;

        await generateGridCurves(
          pick(guideCurve, ["points", "controlVectors", "type"]) as PathInput,
          numAutoSections,
          numAutoColumns,
        );
      }

      await updateBackendPathCollections(project.id, model.id, pathCollection);
    },
    [model, generateGridCurves],
  );

  const updatePathCollection = useCallback(
    async (curve: Curve | Zone) => {
      if ("id" in pathCollection) {
        const result = changePathCollection(model.id, pathCollection.id, curve);
        await updateAfterPathChange(result);
      } else {
        changePathCollectionNew(model.id, curve);
      }
    },
    [model, pathCollection, updateAfterPathChange],
  );

  const appendPoint = useCallback(
    async (point: Vector3) => {
      const updatedPathCollection = dispatch({ type: PathActionType.ADD_LAST_POINT, point });
      const result = await getV3dApi().generateCurve(model.mesh3DBase, updatedPathCollection);
      await updatePathCollection(result);
    },
    [model, dispatch, updatePathCollection],
  );

  const removePath = useCallback(async () => {
    if ("id" in pathCollection) {
      removePathCollection(model.id, pathCollection.id);
      await deleteBackendPathCollection(project.id, model.id, pathCollection.id);
    } else {
      clearNewPathCollection(model.id);
    }
  }, [model, pathCollection]);

  const removePoints = useCallback(
    async (orig: Array<number>) => {
      const indexes = orig.slice();
      indexes.sort((a: number, b: number) => {
        return b - a;
      });
      const updatedPathCollection = dispatch({ type: PathActionType.REMOVE_POINTS, indexes });
      const result = await getV3dApi().generateCurve(model.mesh3DBase, updatedPathCollection);
      await updatePathCollection(result);
    },
    [model, dispatch, updatePathCollection],
  );

  const close = useCallback(async () => {
    const updatedPathCollection = dispatch({ type: PathActionType.CLOSE });
    const result = await getV3dApi().generateZone(model.mesh3DBase, updatedPathCollection);
    await updatePathCollection(result);
  }, [model, dispatch, updatePathCollection]);

  const updatePoint = useCallback(
    async (index: number, point: Vector3) => {
      const updatedPathCollection = dispatch({ type: PathActionType.MOVE_POINT, index, point });
      const result =
        updatedPathCollection.usage === "zone"
          ? await getV3dApi().generateZone(model.mesh3DBase, updatedPathCollection)
          : await getV3dApi().generateCurve(model.mesh3DBase, updatedPathCollection);
      await updatePathCollection(result);
    },
    [model, dispatch, updatePathCollection],
  );

  const changeVector = useCallback(
    async (index: number, vector: Vector3) => {
      const updatedPathCollection = dispatch({ type: PathActionType.CHANGE_VECTOR, index, vector });
      const result =
        updatedPathCollection.usage === "zone"
          ? await getV3dApi().generateZone(model.mesh3DBase, updatedPathCollection)
          : await getV3dApi().generateCurve(model.mesh3DBase, updatedPathCollection);
      await updatePathCollection(result);
    },
    [model, pathCollection, dispatch, updatePathCollection],
  );

  const removeVector = useCallback(
    async (index: number) => {
      if (pathCollection.type !== "BezierPath") throw new Error("Can't remove vector from a non-bezier Path");
      const updatedPathCollection = dispatch({ type: PathActionType.REMOVE_VECTOR, index });
      const result =
        updatedPathCollection.usage === "zone"
          ? await getV3dApi().generateZone(model.mesh3DBase, updatedPathCollection)
          : await getV3dApi().generateCurve(model.mesh3DBase, updatedPathCollection);
      await updatePathCollection(result);
    },
    [model, pathCollection, dispatch, updatePathCollection],
  );

  const getNormalAndTangent = useCallback(
    async (index: number) => {
      if (pathCollection.normalsAndTangents.length > index) {
        return pathCollection.normalsAndTangents[index];
      }

      if (pathCollection.points.length < 2 || index >= pathCollection.points.length) {
        return { first: [0, 0, 0] as Vector3, second: [0, 0, 0] as Vector3 };
      }

      return (await getV3dApi().generateCurve(model.mesh3DBase, pathCollection)).normalsAndTangents[index];
    },
    [model.mesh3DBase, pathCollection],
  );

  const toggleBend = useCallback(
    async (index: number) => {
      if (pathCollection.controlVectors?.[index]?.[0]?.some((v) => v !== 0) ?? false) {
        await removeVector(index);
      } else {
        const tangent = pathCollection.normalsAndTangents[index]?.second;

        if (tangent) {
          await changeVector(index, tangent);
        }
      }
    },
    [pathCollection, changeVector, removeVector],
  );

  const pushPath = useCallback(
    async (closePath?: boolean) => {
      if (closePath) {
        await close();
      }

      const usage = closePath ? "zone" : noGuide ? "guide" : null;
      const idx = Object.keys(pathCollections[model.id]?.collections ?? {}).length;
      const layerName = (closePath ? t("types.closed") : t("types.curve")) + ` ${idx}`;

      const pathCollection = changePathCollectionNew(model.id, { usage });
      const response = await createBackendPathCollection(project.id, model.id, {
        ...pathCollection,
        name: layerName,
        attributes: {},
      });

      pushPathCollection(model.id, { id: response.id, name: layerName, attributes: {} });

      if (pathCollection.usage === "guide") {
        const numAutoColumns = model.attributes.columns?.count || 0;
        const numAutoSections = model.attributes.sections?.count || 0;

        await generateGridCurves(pathCollection, numAutoSections, numAutoColumns);
      }
    },
    [model, project, pathCollections, close, noGuide],
  );

  const flipPath = useCallback(async () => {
    const updated = {
      ...pathCollection,
      points: pathCollection.points.reverse(),
      controlVectors: pathCollection.controlVectors?.reverse() ?? [],
      isVoid: pathCollection.usage === "zone" ? pathCollection.isVoid : undefined,
    } as PathInput | ZoneInput;
    const result =
      pathCollection.usage === "zone"
        ? await getV3dApi().generateZone(model.mesh3DBase, updated)
        : await getV3dApi().generateCurve(model.mesh3DBase, updated);
    await updatePathCollection(result);
  }, [model, pathCollection, updatePathCollection]);

  return {
    pathCollection,
    removePath,
    appendPoint,
    removePoints,
    updatePoint,
    changeVector,
    removeVector,
    pushPath,
    getNormalAndTangent,
    flipPath,
    toggleBend,
  };
}
