import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { ReactNode, useCallback, useMemo } from "react";
import { Model } from "@models/project";
import { AccordionItem } from "@chakra-ui/react";
import { useModelsStore } from "@state/models";
import { useProjectState } from "@state";
import { useBackendPathCollection } from "@hooks/project/useBackendPathCollection";
import { useModelApi, useCalibrationCurves, useTranslation } from "@hooks";
import { PathCollection, Project } from "@models/backend";
import { MeshIcon } from "./icons";
import { SidebarGroup } from "./SidebarGroup";
import { SidebarLayer } from "./SidebarLayer";
import { LayerValue, PathInfo, ColumnInfo, SectionInfo } from "./Common";

const classifiedLayers = (
  paths: Array<PathInfo>,
  onRename: (layerId: string, newValue: string) => void,
  changeUsageAndOrder: (layerId: string, usage: PathCollection["usage"], newOrder: Model["zoneOrder"]) => void,
) => {
  const guidePath: Array<ReactNode> = [];
  const zones: Array<ReactNode> = [];
  const unused: Array<ReactNode> = [];
  let zoneIndex = 0;
  const allZones: Required<Model>["zoneOrder"] = [];

  paths.forEach(({ key, name, icon, layerId, layerType, group, isLoop, ...rest }) => {
    const handleLayerRename = (newValue: string) => {
      onRename(layerId, newValue);
    };
    let canDrop: ((item: LayerValue) => boolean) | undefined;
    let drop: ((item: LayerValue) => void) | undefined;
    let thisIndex: number | undefined;
    if (group === "zone") {
      canDrop = ({ type }) => type === "path";
      thisIndex = zoneIndex;
      drop = ({ layerData: { id } }) => {
        const copy = allZones.filter((val) => val[0] !== id);
        copy.splice(thisIndex!, 0, [id, -1]);
        for (let i = 0; i < copy.length; i++) {
          copy[i][1] = i;
        }
        changeUsageAndOrder(id, "zone", copy);
      };
      allZones.push([layerId, thisIndex]);
      zoneIndex += 1;
    }

    const layer = (
      <SidebarLayer
        key={key}
        name={name}
        icon={icon}
        canDrop={canDrop}
        drop={drop}
        layerType={layerType}
        layerData={{ id: layerId, group, isLoop, zoneIndex: thisIndex }}
        displacement={1}
        secondary={group === "unused"}
        onRename={handleLayerRename}
        {...rest}
        data-test-id={`${group}-path-layer`}
      />
    );

    switch (group) {
      case "guide":
        guidePath.push(layer);
        break;
      case "zone":
        zones.push(layer);
        break;
      default:
        unused.push(layer);
    }
  });

  return [guidePath, zones, unused];
};
const sectionLayers = (sections: Array<SectionInfo>) => {
  const sectionsGroup: Array<ReactNode> = [];
  sections.forEach(({ key, name, icon, layerId, layerType, group, ...rest }) => {
    const layer = (
      <SidebarLayer
        key={key}
        name={name}
        icon={icon}
        layerType={layerType}
        layerData={{ id: layerId, group, isLoop: false }}
        displacement={1}
        secondary={false}
        isEditable={false}
        data-test-id={`${group}-layer`}
        {...rest}
      />
    );
    sectionsGroup.push(layer);
  });
  return [sectionsGroup];
};

const columnLayers = (columns: Array<ColumnInfo>) => {
  const columnsGroup: Array<ReactNode> = [];
  columns.forEach(({ key, name, icon, layerId, layerType, group, ...rest }) => {
    const layer = (
      <SidebarLayer
        key={key}
        name={name}
        icon={icon}
        layerType={layerType}
        layerData={{ id: layerId, group, isLoop: false }}
        displacement={1}
        secondary={false}
        isEditable={false}
        data-test-id={`${group}-layer`}
        {...rest}
      />
    );
    columnsGroup.push(layer);
  });
  return [columnsGroup];
};

type SidebarModelLayerProps = {
  project: Project;
  model: Model;
  paths: Array<PathInfo>;
  sections: Array<SectionInfo>;
  columns: Array<ColumnInfo>;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
  onRename: (modelId: string, layerId: string | null, newValue: string) => void;
  selected: boolean;
  hovered: boolean;
};

export function SidebarModelLayer({
  project,
  model,
  paths,
  sections,
  columns,
  onRename,
  ...rest
}: SidebarModelLayerProps) {
  const { id: projectId } = project;
  const {
    changePathCollection,
    pathCollections,
    selectedObjects,
    setSelectedObjects,
    hoveredObject,
    setHoveredObject,
  } = useModelsStore(
    ({
      changePathCollection,
      pathCollections,
      selectedObjects,
      setSelectedObjects,
      hoveredObject,
      setHoveredObject,
    }) => ({
      changePathCollection,
      pathCollections,
      selectedObjects,
      setSelectedObjects,
      hoveredObject,
      setHoveredObject,
    }),
  );
  const { set3DToolbarSelection } = useProjectState();
  const { updateBackendPathCollection } = useBackendPathCollection();
  const { deleteReferenceModel, patchModel } = useModelApi();
  const { generateGridCurves } = useCalibrationCurves({ project, model });
  const { t } = useTranslation("project.sidebar.layers.groups");

  const changeUsageAndOrder = useCallback(
    async (layerId: string, usage: PathCollection["usage"], newOrder?: Model["zoneOrder"]) => {
      const collection = pathCollections[model.id].collections[layerId];
      let changes = { usage };
      if (collection.usage !== usage && usage === "zone") {
        changes = { ...changes, ...(await getV3dApi().generateZone(model.mesh3DBase, collection)) };
      }
      const changed = changePathCollection(model.id, layerId, changes);
      const guideSource = changed.find((collection) => collection.usage === "guide");
      if (guideSource) {
        const numAutoSections = model.attributes.sections?.count || 0;
        const numAutoColumns = model.attributes.columns?.count || 0;
        await generateGridCurves(guideSource, numAutoSections, numAutoColumns);
      }
      for (const collection of changed) {
        await updateBackendPathCollection(projectId, model.id, collection);
      }
      if (newOrder) {
        await patchModel(projectId, model.id, { zoneOrder: newOrder }).catch((error) => {
          console.error(`Failed to update zone order:`, error);
        });
      }
      setSelectedObjects([{ id: layerId, type: "path", modelId: model.id }]);
    },
    [changePathCollection, model.id, updateBackendPathCollection, projectId, patchModel],
  );

  const [guidePath, zones, unused] = useMemo(() => {
    const updatedPaths = paths.map((path) => {
      const backendCollection = pathCollections[model.id]?.collections[path.layerId];
      return {
        ...path,
        name: backendCollection?.name || path.name,
      };
    });

    return classifiedLayers(
      updatedPaths,
      (layerId, newValue) => onRename(model.id, layerId, newValue),
      changeUsageAndOrder,
    );
  }, [paths, pathCollections, model.id, onRename, changeUsageAndOrder]);

  const [sectionsGroup] = useMemo(() => sectionLayers(sections), [sections]);
  const [columnsGroup] = useMemo(() => columnLayers(columns), [columns]);

  return (
    <AccordionItem p={0}>
      <SidebarLayer
        isEditable={true}
        name={model.name}
        icon={<MeshIcon />}
        layerType="model"
        onRename={(newName: string) => onRename(model.id, null, newName)}
        {...rest}
      >
        <SidebarGroup
          name={t("guide")}
          canDrop={({ type, layerData }) => type === "path" && !layerData?.isLoop}
          drop={({ layerData }) => changeUsageAndOrder(layerData.id, "guide")}
          replace={true}
          group="guide"
        >
          {guidePath}
        </SidebarGroup>
        <SidebarGroup name={t("section")} replace={false} group="section">
          {sectionsGroup}
        </SidebarGroup>
        <SidebarGroup name={t("column")} replace={false} group="column">
          {columnsGroup}
        </SidebarGroup>
        <SidebarGroup
          name={t("zone")}
          canDrop={zones.length === 0 ? ({ type }) => type === "path" : undefined}
          drop={({ layerData }) => changeUsageAndOrder(layerData.id, "zone")}
          replace={false}
          group="zone"
        >
          {zones}
        </SidebarGroup>
        <SidebarGroup name={t("reference")} replace={false} group="reference">
          {model.references.map((referenceModel) => {
            const id = referenceModel.id;

            return (
              <SidebarLayer
                key={id}
                name={referenceModel.name}
                icon={<MeshIcon />}
                layerData={{ id, group: "reference" }}
                displacement={1}
                isEditable={true}
                secondary={false}
                selected={!!selectedObjects.find((o) => o.id === id)}
                hovered={hoveredObject?.id === id}
                onSelect={() => {
                  setSelectedObjects([{ id, type: "reference", modelId: model.id }]);
                  set3DToolbarSelection("select");
                }}
                onHover={() => setHoveredObject({ id, type: "reference", modelId: model.id })}
                onUnhover={() => setHoveredObject(null)}
                onDelete={() => deleteReferenceModel(projectId, id)}
              />
            );
          })}
        </SidebarGroup>
        <SidebarGroup
          name={t("unused")}
          canDrop={({ type }) => type === "path"}
          drop={({ layerData }) => changeUsageAndOrder(layerData.id, null)}
          replace={false}
          group="unused"
        >
          {unused}
        </SidebarGroup>
      </SidebarLayer>
    </AccordionItem>
  );
}
