import "@/globals";
import { Model, PathCollection } from "@models/project";
import { computeZonesFromPathCollections } from "@utils";
import { getV3dApi } from "@utils/project/initV3dApi.ts";
import { Curve, GridCurves, PathInput, Vector3 } from "@variant-tech/pattern-derivation";

export async function generateGridCurves(
  model: Model,
  guideCurve: PathInput,
  pathCollections: PathCollection[],
  columnsCount: number,
  sectionsCount: number,
): Promise<GridCurves> {
  return await getV3dApi().generateGridCurves(model.mesh3DBase, {
    guideCurve,
    zones: computeZonesFromPathCollections(pathCollections, model),
    columnsCount,
    sectionsCount,
  });
}

export async function computeSectionCurves(
  model: Model,
  guideCurve: PathInput,
  pathCollections: PathCollection[],
  anchorPoints: Vector3[],
): Promise<Curve[]> {
  return await getV3dApi().computeSectionCurves(model.mesh3DBase, {
    guideCurve,
    anchorPoints,
    zones: computeZonesFromPathCollections(pathCollections, model),
  });
}

export async function computeColumnCurves(
  model: Model,
  guideCurve: PathInput,
  pathCollections: PathCollection[],
  anchorPoints: Vector3[],
): Promise<Curve[]> {
  return await getV3dApi().computeColumnCurves(model.mesh3DBase, {
    guideCurve,
    anchorPoints,
    zones: computeZonesFromPathCollections(pathCollections, model),
  });
}
