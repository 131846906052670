import { PropsWithChildren } from "react";
import { ProjectContext, ProjectContextProps } from "./projectContext";

export function CurrentProjectProvider({
  project,
  v3dApi,
  children,
}: PropsWithChildren<Required<ProjectContextProps>>) {
  return <ProjectContext.Provider value={{ project, v3dApi }}>{children}</ProjectContext.Provider>;
}
