import BackendApi from "@api/BackendApi";
import { AuthHeaders, useAuth } from "@auth";
import { useProgressToast, useTranslation } from "@hooks";
import { useCallback } from "react";
import { useModelLoader } from "./useModelLoader";

const createBackendModel = async ({
  projectId,
  parentModelId,
  content,
  decimated,
  headers,
  update,
}: {
  projectId: string;
  parentModelId?: string;
  content: File;
  decimated?: File;
  headers: AuthHeaders["headers"];
  update: (percentage: number) => void;
}) => {
  let model = await BackendApi.addProjectModel({
    headers,
    params: { projectId },
    body: {
      parentId: parentModelId,
    },
    content,
    onProgress: ({ percentage }) => update(percentage),
  });

  if (decimated) {
    model = await BackendApi.decimateProjectModel({
      headers,
      params: { projectId, id: model.id },
      body: {
        parentId: parentModelId,
      },
      content: decimated,
      onProgress: ({ percentage }) => update(percentage),
    });
  }

  return model;
};

export function useModelUpload({ projectId }: { projectId: string }) {
  const { headers } = useAuth();
  const { t } = useTranslation("hooks.models.upload.progress");
  const toast = useProgressToast();
  const { loadModel } = useModelLoader();
  const onModelUpload = useCallback(
    async (content: File, parentModelId?: string) => {
      const { update } = toast(t, { title: content.name, status: "in-progress" });

      try {
        const model = await createBackendModel({
          projectId,
          parentModelId,
          content,
          headers,
          update: (progress: number) => update({ status: "in-progress", progress }),
        });
        await loadModel(model);
        update({ status: "success" });
      } catch (error) {
        console.log({ message: "Failed to upload model", error, content, projectId });
        update({ status: "error" });
        throw error;
      }
    },
    [headers, loadModel, projectId, t, toast],
  );

  return { onModelUpload };
}
