import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { DropBox, useSemanticTokens, spacing, EditableInput } from "@design-system";
import { cloneElement, Fragment, ReactElement, ReactNode } from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { useHotkeys } from "react-hotkeys-hook";
import { LayerData, LayerValue } from "./Common";

const IconSizeMedium = "16px";

type SidebarLayerProps = {
  layerType?: "model" | "path" | "section" | "column" | "reference";
  name: string;
  canDrop?: (item: LayerValue) => boolean;
  drop?: (item: LayerValue) => void;
  icon: ReactElement;
  secondary?: boolean;
  children?: ReactNode;
  displacement?: number;
  // Drag N Drop relevant data.
  layerData?: LayerData;
  onSelect: () => void;
  onHover: () => void;
  onUnhover: () => void;
  selected: boolean;
  hovered: boolean;
  onRename?: (newValue: string) => void;
  isEditable: boolean;
  onDelete?: () => void;
  "data-test-id"?: string;
};

type LayerStyles = {
  [key in "model" | "path" | "section" | "column" | "reference"]: {
    icon: {
      defaultColor: string;
      selectedColor: string;
      secondaryColor: string;
    };
    text: {
      defaultColor: string;
      selectedColor: string;
      secondaryColor: string;
    };
  };
};

function useLayerStyles(): LayerStyles {
  const semanticTokens = useSemanticTokens();

  return {
    model: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
    path: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
    section: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
    column: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
    reference: {
      icon: {
        defaultColor: semanticTokens.icons.primary,
        selectedColor: semanticTokens.icons.secondary,
        secondaryColor: semanticTokens.icons.accent.primary,
      },
      text: {
        defaultColor: semanticTokens.text.classic.primary,
        selectedColor: semanticTokens.text.classic.primary,
        secondaryColor: semanticTokens.text.classic.secondary,
      },
    },
  };
}

export function SidebarLayer({
  name,
  canDrop: canDropFunction,
  drop: dropFunction,
  layerType = "model",
  icon,
  children,
  displacement = 0,
  layerData,
  secondary,
  selected,
  hovered,
  onSelect,
  onHover,
  onUnhover,
  onRename,
  isEditable = true,
  onDelete,
  ...rest
}: SidebarLayerProps) {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "path",
      canDrop: canDropFunction,
      drop: dropFunction,
      collect: (monitor: DropTargetMonitor) => ({
        item: monitor.getItem(),
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [canDropFunction, dropFunction],
  );
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: layerType,
      item: { layerData, type: layerType },
      layerData,
      canDrag: () => layerType === "path",
      collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }),
    [layerData, layerType],
  );
  const layerStyles = useLayerStyles();
  const {
    icon: { defaultColor, selectedColor },
    text: { defaultColor: textDefaultColor, selectedColor: textSelectedColor, secondaryColor: textSecondaryColor },
  } = layerStyles[layerType];
  const semanticTokens = useSemanticTokens();

  icon = cloneElement(icon, {
    className: "layerIcon",
    boxSize: IconSizeMedium,
    color: selected ? selectedColor : defaultColor,
  });

  useHotkeys(["delete", "backspace"], () => {
    if (selected && onDelete) {
      onDelete();
    }
  });

  let containerVariant = hovered ? "sidebarLayerHovered" : "sidebarLayer";

  if (selected) {
    containerVariant = "sidebarLayerSelected";
  }
  const showDrop = !!canDropFunction && isOver;
  return (
    <Fragment>
      <Accordion
        ref={(node) => drag(drop(node))}
        opacity={isDragging ? 0.5 : 1}
        allowMultiple
        defaultIndex={[0]}
        w="full"
        data-test-id={rest["data-test-id"]}
      >
        <AccordionItem w="full">
          {showDrop ? <DropBox variant={canDrop ? "ok" : "error"} /> : null}
          <Container
            alignItems="center"
            gap={spacing.space[50]}
            variant={containerVariant}
            height="1.5rem"
            _hover={
              selected
                ? {}
                : {
                    bg: semanticTokens.surface.classic.secondary,
                    ".row-hover": { visibility: "visible" },
                  }
            }
            onClick={onSelect}
            onPointerEnter={onHover}
            onPointerLeave={onUnhover}
          >
            <Flex alignItems="center" height="100%">
              {displacement ? <Spacer mx={`${0.5 * displacement}rem`} /> : null}
              {children ? (
                <AccordionButton
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  userSelect="none"
                >
                  <AccordionIcon color={selected ? semanticTokens.icons.invert : semanticTokens.icons.primary} />
                </AccordionButton>
              ) : (
                <Spacer mx="0.5rem" />
              )}
              {icon}
            </Flex>
            <EditableInput
              initialValue={name}
              textColor={selected ? textSelectedColor : secondary ? textSecondaryColor : textDefaultColor}
              textVariant="2xs-regular"
              onChange={onRename}
              isEditable={isEditable}
            />
          </Container>
          {children && (
            <HStack role="group" gap={0}>
              <Box w="full">
                <AccordionPanel w="full" rowGap={0} marginTop={0}>
                  {children}
                </AccordionPanel>
              </Box>
            </HStack>
          )}
        </AccordionItem>
      </Accordion>
    </Fragment>
  );
}
