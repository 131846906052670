import { createContext, Dispatch, SetStateAction, useContext } from "react";

export const ViewVersionContext = createContext<[number, Dispatch<SetStateAction<number>>]>([
  0,
  () => {
    /**/
  },
]);

export function useViewVersion() {
  return useContext(ViewVersionContext);
}
