import { Accordion, Button, Container, HStack, Input, Switch, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { pathCollectionAttributes as defaultPathCollectionAttributes } from "@defaults/project";
import { Dropdown, DropdownItem, spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { useTranslation } from "@hooks";
import { useBackendPathCollection, usePath } from "@hooks/project";
import { KnitStructure, Project as ProjectData } from "@models/backend";
import { Model, PathCollection } from "@models/project";
import { useModelsStore } from "@state/models";
import { useCallback, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { StructureLabel } from "./Common";
import { FlipIcon } from "./icons";
import { SidebarPropertiesSection } from "./SidebarPropertiesSection";

const {
  left: defaultLeftBoundary,
  right: defaultRightBoundary,
  top: defaultTopBoundary,
  bottom: defaultBottomBoundary,
} = defaultPathCollectionAttributes.transition;

type PathCollectionProps = {
  modelId: Model["id"];
  pathCollectionId: NonNullable<PathCollection["id"]>;
  project: ProjectData;
} & StyleProps;

export function SidebarPathCollectionProperties({ modelId, pathCollectionId, project, ...props }: PathCollectionProps) {
  const { t } = useTranslation("projectProperties.propertiesSidebar.tools");
  const { pathCollection, changePathCollection } = useModelsStore(
    useShallow(({ pathCollections, changePathCollection }) => ({
      pathCollection: pathCollections[modelId]?.collections[pathCollectionId],
      changePathCollection,
    })),
  );
  const model = useModelsStore(useShallow(({ models }) => models.find(({ id }) => modelId === id)))!;
  const { updateBackendPathCollection } = useBackendPathCollection();
  const knitStructures = useModelsStore(({ knitStructures }) => knitStructures);
  const { flipPath } = usePath(project, model, pathCollection);

  const semanticTokens = useSemanticTokens();
  const textClassicSecondary = semanticTokens.text.classic.secondary;
  const { border } = useThemeTokens();

  const modelStructure = model?.attributes?.knitStructure;
  const defaultStructure = knitStructures.find((structure) => structure.isDefault == true);
  const selectedStructure = pathCollection?.attributes?.knitStructure ?? modelStructure ?? defaultStructure;

  const pathCollectionCourse =
    pathCollection?.attributes?.stitchDensity?.course ?? selectedStructure?.courseDensity ?? 0;

  const pathCollectionLegacyBoundary = pathCollection?.attributes?.transition?.boundary;

  const pathCollectionLeftBoundary =
    pathCollection?.attributes?.transition?.left ?? pathCollectionLegacyBoundary ?? defaultLeftBoundary;
  const pathCollectionRightBoundary =
    pathCollection?.attributes?.transition?.right ?? pathCollectionLegacyBoundary ?? defaultRightBoundary;
  const pathCollectionTopBoundary = pathCollection?.attributes?.transition?.top ?? defaultTopBoundary;
  const pathCollectionBottomBoundary = pathCollection?.attributes?.transition?.bottom ?? defaultBottomBoundary;

  const isKnitStructureDefault = !pathCollection?.attributes?.knitStructure;
  const isStitchDensityDefault = !pathCollection?.attributes?.stitchDensity;

  const [selectedCourse, setSelectedCourse] = useState<string>(pathCollectionCourse.toFixed(3) ?? "");
  const [selectedRightBoundary, setSelectedRightBoundary] = useState<string>(pathCollectionRightBoundary.toString());
  const [selectedLeftBoundary, setSelectedLeftBoundary] = useState<string>(pathCollectionLeftBoundary.toString());
  const [selectedTopBoundary, setSelectedTopBoundary] = useState<string>(pathCollectionTopBoundary.toString());
  const [selectedBottomBoundary, setSelectedBottomBoundary] = useState<string>(pathCollectionBottomBoundary.toString());

  const setKnitStructure = useCallback(
    async (newStructure: KnitStructure | null) => {
      const updated = changePathCollection(modelId, pathCollectionId, {
        attributes: {
          knitStructure: newStructure,
        },
      });

      await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
    },
    [isStitchDensityDefault, knitStructures, changePathCollection],
  );

  const saveCourse = useCallback(async () => {
    const course = isNaN(parseFloat(selectedCourse)) ? null : parseFloat(selectedCourse);
    const updated = changePathCollection(modelId, pathCollectionId, { attributes: { stitchDensity: { course } } });
    setSelectedCourse(course !== null ? course.toFixed(3) : "");
    await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
  }, [selectedCourse, pathCollectionId, modelId]);

  const saveBoundary = useCallback(async () => {
    const left = isNaN(parseInt(selectedLeftBoundary)) ? defaultLeftBoundary : parseInt(selectedLeftBoundary);
    const right = isNaN(parseInt(selectedRightBoundary)) ? defaultRightBoundary : parseInt(selectedRightBoundary);
    const top = isNaN(parseInt(selectedTopBoundary)) ? defaultTopBoundary : parseInt(selectedTopBoundary);
    const bottom = isNaN(parseInt(selectedBottomBoundary)) ? defaultBottomBoundary : parseInt(selectedBottomBoundary);
    const updated = changePathCollection(modelId, pathCollectionId, {
      attributes: { transition: { left, right, top, bottom } },
    });
    setSelectedRightBoundary(right.toString());
    setSelectedLeftBoundary(left.toString());
    setSelectedTopBoundary(top.toString());
    setSelectedBottomBoundary(bottom.toString());
    await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
  }, [
    selectedLeftBoundary,
    selectedRightBoundary,
    selectedTopBoundary,
    selectedBottomBoundary,
    pathCollectionId,
    modelId,
  ]);

  if (!pathCollection) {
    return null;
  }
  const isZone = pathCollection.usage === "zone";
  const isGuide = pathCollection.usage === "guide";

  return (
    <Container variant="classic" borderTopWidth={border.width} {...props}>
      <Accordion variant="outline" allowMultiple defaultIndex={[0, 1, 2]} paddingX={spacing.space["200"]} w="full">
        {isZone ? (
          <>
            <SidebarPropertiesSection borderTop="unset" title={t("curveSettings.knitStructure.label")}>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular">
                  {t("curveSettings.knitStructure.default.label")}
                </Text>
                <Switch
                  isChecked={isKnitStructureDefault}
                  marginLeft="auto"
                  size="sm"
                  onChange={async (e) => {
                    if (e.target.checked) {
                      setKnitStructure(null);
                    } else {
                      setKnitStructure(modelStructure ?? defaultStructure!);
                    }
                  }}
                />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Dropdown
                  variant={isKnitStructureDefault ? "classic-dropdown-disabled" : "classic-dropdown"}
                  label={<StructureLabel structure={selectedStructure} />}
                  buttonStyleProps={{
                    size: "sm",
                    width: "100%",
                    height: spacing.space["600"],
                    isDisabled: isKnitStructureDefault,
                    cursor: isKnitStructureDefault ? "not-allowed" : "pointer",
                  }}
                  menuListStyleProps={{
                    paddingY: spacing.space["100"],
                    maxHeight: "200px",
                    overflowY: "auto",
                    maxWidth: "200px",
                  }}
                >
                  {knitStructures.map((structure) => (
                    <DropdownItem
                      key={structure.id}
                      content={<StructureLabel structure={structure} />}
                      value={structure.id}
                      selectedValue={selectedStructure?.id}
                      setSelectedValue={async () => {
                        setKnitStructure(structure);
                        if (isStitchDensityDefault) {
                          setSelectedCourse(structure.courseDensity?.toFixed(3) ?? "");
                        }
                      }}
                    />
                  ))}
                </Dropdown>
              </HStack>
            </SidebarPropertiesSection>
            <SidebarPropertiesSection title={t("stitchDensity.label")}>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular">
                  {t("stitchDensity.default.knitStructure.label")}
                </Text>
                <Switch
                  isChecked={isStitchDensityDefault}
                  marginLeft="auto"
                  size="sm"
                  onChange={async (e) => {
                    let updated: PathCollection[] = [];
                    if (e.target.checked) {
                      updated = changePathCollection(modelId, pathCollectionId, {
                        attributes: { stitchDensity: null },
                      });
                    } else {
                      updated = changePathCollection(modelId, pathCollectionId, {
                        attributes: { stitchDensity: { course: selectedStructure?.courseDensity ?? 0 } },
                      });
                    }
                    setSelectedCourse(selectedStructure?.courseDensity?.toFixed(3) ?? "");
                    await Promise.all(updated.map((u) => updateBackendPathCollection(project.id, modelId, u)));
                  }}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("stitchDensity.course.label")}
                </Text>
                <Input
                  disabled={isStitchDensityDefault}
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  onBlur={saveCourse}
                  value={selectedCourse}
                />
              </HStack>
            </SidebarPropertiesSection>
            <SidebarPropertiesSection title={t("transition.label")}>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("transition.left.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedLeftBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedLeftBoundary}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("transition.right.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedRightBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedRightBoundary}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("transition.top.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedTopBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedTopBoundary}
                />
              </HStack>
              <HStack justifyContent="space-between" w={"100%"}>
                <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                  {t("transition.bottom.label")}
                </Text>
                <Input
                  size="sm"
                  w="50%"
                  height={spacing.space["600"]}
                  type="number"
                  placeholder="0"
                  onChange={(e) => setSelectedBottomBoundary(e.target.value)}
                  onBlur={saveBoundary}
                  value={selectedBottomBoundary}
                />
              </HStack>
            </SidebarPropertiesSection>
          </>
        ) : null}
        {isGuide ? (
          <SidebarPropertiesSection borderTop="unset" title={t("curveSettings.label")}>
            <HStack justifyContent="space-between" w={"100%"}>
              <Text color={textClassicSecondary} variant="2xs-regular" w="50%">
                {t("curveSettings.direction.label")}
              </Text>
              <Button
                onClick={async () => {
                  await flipPath();
                }}
                size="xs"
                variant="outline"
                width={spacing.space["800"]}
              >
                <FlipIcon width={spacing.space["400"]} />
              </Button>
            </HStack>
          </SidebarPropertiesSection>
        ) : null}
      </Accordion>
    </Container>
  );
}
