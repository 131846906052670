import { Accordion, Container, HStack, Switch, Text } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { spacing, useSemanticTokens, useThemeTokens } from "@design-system";
import { useCalibrationCurves, useTranslation } from "@hooks";
import { Project as ProjectData } from "@models/backend";
import { SidebarPropertiesSection } from "./SidebarPropertiesSection";
import { useModelsStore } from "@state/models";
import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

interface SectionPropertiesProps extends StyleProps {
  project: ProjectData;
  modelId: string;
  sectionId: string;
}

export function SidebarSectionProperties({ project, modelId, sectionId, ...props }: SectionPropertiesProps) {
  const { t } = useTranslation("projectProperties.sidebarSectionProperties");
  const semanticTokens = useSemanticTokens();
  const textClassicSecondary = semanticTokens.text.classic.secondary;
  const { border } = useThemeTokens();

  const { sections, models } = useModelsStore(
    useShallow(({ sections, models }) => ({
      sections,
      models,
    })),
  );

  const model = models.find((m) => m.id === modelId);
  const { updateSectionCurve } = useCalibrationCurves({ project, model: model! });

  const sectionAnchors = sections[modelId]?.sectionCurves || [];
  const section = sectionAnchors.find((section: { id: string }) => section.id === sectionId);
  const excludeGoring = section?.attributes?.excludeGoring ?? false;

  const handleGoringChange = useCallback(
    async (checked: boolean) => {
      if (section?.id) {
        await updateSectionCurve(modelId, section.id, {
          excludeGoring: checked,
        });
      }
    },
    [modelId, section?.id, updateSectionCurve],
  );

  if (!section || !model) {
    return null;
  }

  return (
    <Container variant="classic" borderTopWidth={border.width} {...props}>
      <Accordion variant="outline" allowMultiple defaultIndex={[0]} paddingX={spacing.space["200"]} w="full">
        <SidebarPropertiesSection borderTop="unset" title={t("title")}>
          <HStack justifyContent="space-between" w="full">
            <Text color={textClassicSecondary} variant="2xs-regular">
              {t("flatKnit.label")}
            </Text>
            <Switch
              isChecked={excludeGoring}
              marginLeft="auto"
              size="sm"
              onChange={(e) => handleGoringChange(e.target.checked)}
            />
          </HStack>
        </SidebarPropertiesSection>
      </Accordion>
    </Container>
  );
}
