import { useMemo, useCallback } from "react";
import { GlobalPresenceState, GlobalPresenceUser, useGlobalPresence } from "@hooks/useGlobalPresence";
import { User } from "@auth";

interface UseProjectPresenceResult {
  presenceUsers: GlobalPresenceUserWithKey[];
  getActiveUsersForProject: (projectId: string) => GlobalPresenceUserWithKey[];
}

interface GlobalPresenceUserWithKey extends GlobalPresenceUser {
  uniqueKey: string;
}

export function useProjectPresence(user: User | null, projectId?: string | null): UseProjectPresenceResult {
  const safeUser = user ?? ({} as User);

  const { globalPresenceState } = useGlobalPresence(safeUser, projectId ?? null);

  const presenceUsers = useMemo(() => {
    if (!user || !projectId) return [];
    return getPresencesForProject(globalPresenceState, projectId);
  }, [globalPresenceState, projectId, user]);

  const getActiveUsersForProject = useCallback(
    (id: string) => {
      if (!user) return [];
      return getPresencesForProject(globalPresenceState, id);
    },
    [globalPresenceState, user],
  );

  return { presenceUsers, getActiveUsersForProject };
}

function getPresencesForProject(globalState: GlobalPresenceState, projectId: string): GlobalPresenceUserWithKey[] {
  const allPresences = Object.values(globalState).flat();

  return allPresences
    .filter((presence) => presence.projectId === projectId)
    .map((presence, index) => ({
      ...presence,
      uniqueKey: `${presence.id}-${presence.projectId}-${index}`,
    }));
}
