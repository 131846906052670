import { CPair, Vector3 as VP } from "@variant-tech/pattern-derivation";
import { Mesh, Vector3 } from "three";

export enum Moving {
  VECTOR_LEFT = "VECTOR_LEFT",
  VECTOR_RIGHT = "VECTOR_RIGHT",
}

export enum Hovered {
  VECTOR_LEFT = "VECTOR_LEFT",
  VECTOR_RIGHT = "VECTOR_RIGHT",
}

const MIN_LENGTH = 0.5;
const EPS = 0.001;
export const MIN_CHANGE = 0.1;

export type BezHandlesProps = {
  point: VP;
  controlVectors: [VP, VP];
  getNormalAndTangent: () => Promise<CPair<VP, VP>>;
  mesh: Mesh;
  changeVector: (vector: VP) => void;
  initialMoving?: Moving.VECTOR_RIGHT;
};

export const vectorHandles = (
  origin: VP,
  controlVectors: [VP, VP],
  normalAndTangent: CPair<VP, VP>,
): [Vector3, Vector3] | [] => {
  const [apiL, apiR] = controlVectors ?? [
    [0, 0, 0],
    [0, 0, 0],
  ];
  let l = new Vector3(...apiL);
  let r = new Vector3(...apiR);
  const ll = l.length();
  const lr = r.length();

  const { first: normal } = normalAndTangent;
  const ln = new Vector3(...normal).length();
  if (ll < EPS) {
    // wait for normal and tangent only when they are needed.
    if (ln < EPS) return [];
    const { second: tangent } = normalAndTangent;
    l = new Vector3(...tangent);
    l.negate();
  }
  if (lr < EPS) {
    // wait for normal and tangent only when they are needed.
    if (ln < EPS) return [];
    const { second: tangent } = normalAndTangent;
    r = new Vector3(...tangent);
  }
  l.normalize();
  r.normalize();
  l.multiplyScalar(Math.max(ll, MIN_LENGTH));
  r.multiplyScalar(Math.max(lr, MIN_LENGTH));

  const o = new Vector3(...origin);
  const lpos = o.clone();
  const rpos = o.clone();

  lpos.add(l);
  rpos.add(r);

  return [lpos, rpos];
};
