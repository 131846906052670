import { MachinesState, ModelsStore } from "../types";
import { SetState } from "zustand";
import { Machine } from "@models/backend";

export const machinesSlice = (set: SetState<ModelsStore>): MachinesState => ({
  machines: [],

  setMachines: (machines: Machine[]) => {
    set({ machines });
  },
  clearMachines: () => {
    set({ machines: [] });
  },
});
