import { PropsWithChildren, useState } from "react";
import { CursorContextProps, CursorContext, cursorMap } from "@hooks/useCustomCursor.ts";

export function CursorProvider({ children }: PropsWithChildren) {
  const [cursor, setCursor] = useState<CursorContextProps["cursor"]>(cursorMap["select"]);

  return (
    <CursorContext.Provider
      value={{
        cursor,
        setCursor: (cursor) => setCursor(cursorMap[cursor]),
      }}
    >
      {children}
    </CursorContext.Provider>
  );
}
