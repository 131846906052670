import { Flex, MenuItem, StyleProps, Text } from "@chakra-ui/react";
import { CheckMarkIcon, useThemeTokens } from "@design-system";
import { Dispatch, ReactNode } from "react";

type DropdownItemProps<T> = {
  icon?: JSX.Element;
  label?: string;
  content?: ReactNode;
  value: T;
  selectedValue: T;
  hideSelectedCheck?: boolean;
  setSelectedValue: Dispatch<T>;
  styleProps?: StyleProps;
  variant?: string;
};

export function DropdownItem<T>({
  icon,
  value,
  selectedValue,
  setSelectedValue,
  label,
  content,
  hideSelectedCheck,
  styleProps,
  variant,
}: DropdownItemProps<T>) {
  const {
    dropDownItem: {
      selected: {
        bg,
        icon: { color },
      },
    },
  } = useThemeTokens();
  const body = content ?? label ?? (value ? `${value}` : null);
  const selected = value === selectedValue;

  return (
    <MenuItem onClick={() => setSelectedValue(value)} icon={icon} bg={selected ? bg : undefined} {...styleProps}>
      <Flex width="full">
        {typeof body === "string" ? (
          <Text variant={variant} isTruncated>
            {body}
          </Text>
        ) : (
          body
        )}
        {!hideSelectedCheck && selected && (
          <CheckMarkIcon color={color} width="1rem" height="1rem" marginStart="0.5rem" />
        )}
      </Flex>
    </MenuItem>
  );
}
