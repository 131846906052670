import { Box, Flex, Image, LinkBox, LinkOverlay, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { useSemanticTokens, useThemeTokens, spacing } from "@design-system";
import { ProjectContextMenu } from "@fragments";
import { getProjectPath, useTranslation } from "@hooks";
import { Project } from "@models/backend";
import moment from "moment/moment";
import { NavLink } from "react-router-dom";
import { ActiveUsers } from "@design-system/components/ActiveUsers/ActiveUsers";

interface ProjectCardProps extends Omit<StyleProps, keyof Project> {
  project: Project;
  onProjectUpdated?: (updatedProject: Project) => void;
  onProjectDeleted?: (deletedProject: Project) => void;
  activeUsers?: Array<{
    id: string;
    email: string;
    avatar: string;
    name: string;
  }>;
}

export function ProjectCard({
  project,
  onProjectUpdated,
  onProjectDeleted,
  activeUsers = [],
  ...props
}: ProjectCardProps) {
  const { t } = useTranslation("home.projects.view.attributes");
  const { border } = useThemeTokens();
  const semanticTokens = useSemanticTokens();
  const surfaceSecondary = semanticTokens.surface.secondary;
  const textSecondary = semanticTokens.text.secondary;
  const { isOpen, ...disclosureProps } = useDisclosure();

  return (
    <LinkBox
      as={VStack}
      borderColor={border.color}
      borderWidth={border.width}
      gap="1rem"
      h="18.75rem"
      position="relative"
      role="group"
      transition="background-color 0.2s"
      _hover={{ backgroundColor: surfaceSecondary }}
      {...props}
      data-test-id="project-item"
    >
      <Box p={spacing.space["600"]} w="full">
        <Box h="12rem" w="full">
          {project?.thumbnail && <Image h="full" w="full" fit="contain" src={project.thumbnail} />}
        </Box>

        <Box mt={spacing.space["600"]}>
          <Flex justify="space-between" align="center" mb={-spacing.space["100"]}>
            <LinkOverlay as={NavLink} to={getProjectPath(project.id)}>
              <Text variant="m-bold" noOfLines={1} textOverflow="ellipsis">
                {project.name}
              </Text>
            </LinkOverlay>
          </Flex>

          <Flex justify="space-between" align="center">
            <Text variant="xs-regular" color={textSecondary}>
              {t("updatedAt.label")} {moment(project.updatedAt).fromNow()}
            </Text>
            <ActiveUsers users={activeUsers} maxDisplayed={3} />
          </Flex>
        </Box>
      </Box>

      <Box
        _groupHover={{ opacity: 1 }}
        opacity={isOpen ? 1 : 0}
        transition="opacity 0.2s"
        position="absolute"
        top={spacing.space["600"]}
        right={spacing.space["600"]}
        backgroundColor={surfaceSecondary}
      >
        <ProjectContextMenu
          project={project}
          onProjectUpdated={onProjectUpdated}
          onProjectDeleted={onProjectDeleted}
          isOpen={isOpen}
          {...disclosureProps}
        />
      </Box>
    </LinkBox>
  );
}
