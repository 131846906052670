import BackendApi, { TrackRequest } from "@api/BackendApi.ts";
import { AnalyticsPlugin, IdentifyData, TrackData } from "../types";

class BackendPlugin implements AnalyticsPlugin {
  name = "analytics-backend-plugin";

  [x: string]: unknown;

  private userId: TrackRequest["userId"] = undefined;
  private traits: TrackRequest["traits"] = {};
  private pendingEvents: Omit<TrackRequest, "userId" | "traits">[] = [];

  constructor() {
    setInterval(async () => {
      const pendingEvents = this.pendingEvents;

      this.pendingEvents = [];

      if (!pendingEvents.length) {
        return;
      }

      await BackendApi.track(
        pendingEvents.map((payload) => ({
          ...payload,
          userId: this.userId,
          traits: this.traits,
        })),
      );
    }, 3000);
  }

  track({ payload: { event, properties } }: { payload: TrackData }) {
    this.pendingEvents.push({ event, properties, createdAt: new Date() });
  }

  identify({ payload: { userId, traits } }: { payload: IdentifyData }) {
    this.userId = userId;
    this.traits = traits as Record<string, unknown>;
  }

  loaded() {
    return true;
  }
}

export const backendPlugin = new BackendPlugin();
