import { HStack } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/styled-system";
import { ToggleIconButton } from "@design-system";
import { useTranslation } from "@hooks";
import { useProjectState, Project3DToolbarState } from "@state/project";
import { ReactElement, useEffect } from "react";
import { useCustomCursor } from "@hooks/useCustomCursor";
import { ColumnIcon, PenIcon, SectionIcon, SelectIcon } from "./icons";
import { MeshIcon } from "@fragments/project/sidebars/icons";
import { useModelsStore } from "@state";

export function Tool({
  toolName,
  keys,
  icon,
  disabled,
}: {
  toolName: Project3DToolbarState["_3DToolbarSelection"];
  keys: string;
  icon: ReactElement;
  disabled?: boolean;
}) {
  const { t } = useTranslation("project3D.toolbarLeft.tools");
  const { _3DToolbarSelection, set3DToolbarSelection } = useProjectState();

  return (
    <ToggleIconButton
      key={toolName}
      isSelected={_3DToolbarSelection == toolName}
      onToggle={() => {
        set3DToolbarSelection(toolName);
      }}
      label={t(toolName + ".label")}
      icon={icon}
      shortcut={{
        label: t(toolName + ".label"),
        keys,
      }}
      placement="bottom"
      variant="inverse"
      disabled={disabled}
    />
  );
}

export function Tools3DToolbar(props: StyleProps) {
  const { _3DToolbarSelection } = useProjectState();
  const { process } = useModelsStore(({ process }) => ({ process }));
  const { setCursor } = useCustomCursor();

  useEffect(() => {
    const cursor = () => {
      switch (_3DToolbarSelection) {
        case "select":
          return "select";
        case "pen":
          return "pen";
        case "section":
          return "pen";
        default:
          return "select";
      }
    };
    setCursor(cursor());
  }, [_3DToolbarSelection, setCursor]);

  return (
    <HStack gap={0} {...props}>
      <Tool toolName="select" icon={<SelectIcon />} keys="v" />
      <Tool toolName="pen" icon={<PenIcon />} keys="p" />
      <Tool toolName="section" icon={<SectionIcon />} keys="s" />
      <Tool toolName="column" icon={<ColumnIcon />} keys="c" />
      <Tool toolName="knitmesh" keys="k" icon={<MeshIcon />} disabled={!process} />
    </HStack>
  );
}
