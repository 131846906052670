import { captureException } from "@sentry/react";
import Analytics, { AnalyticsInstance, AnalyticsPlugin, PageData } from "analytics";
import { backendPlugin, consolePlugin } from "./plugins";
import { amplitudeAnalyticsPlugin, CoreAnalyticsConfig } from "./types";

export class CoreAnalytics {
  private analytics!: AnalyticsInstance;

  constructor(config: CoreAnalyticsConfig) {
    try {
      this.analytics = Analytics({
        app: "loop",
        debug: config.debugMode,
        plugins: this.createAnalyticsPlugins(config),
      });
    } catch (error) {
      captureException(error, {
        tags: { action: "ANALYTICS_INIT" },
      });
    }
  }

  public async identify<T>(userId: string, traits?: T): Promise<void> {
    try {
      return await this.analytics.identify(userId, { ...traits, host: window.location.host });
    } catch (error) {
      captureException(error);
    }
  }

  public async page(pageData: PageData): Promise<void> {
    try {
      return await this.analytics.page(pageData);
    } catch (error) {
      captureException(error);
    }
  }

  public async track<T>(eventName: string, properties?: T): Promise<void> {
    try {
      return await this.analytics.track(eventName, properties);
    } catch (error) {
      captureException(error);
    }
  }

  private createAnalyticsPlugins(config: CoreAnalyticsConfig): AnalyticsPlugin[] {
    const plugins: AnalyticsPlugin[] = [];

    if (config.debugMode) {
      plugins.push(consolePlugin);
    }

    plugins.push(backendPlugin);

    if (config.amplitude?.apiKey) {
      const amplitudePlugin = amplitudeAnalyticsPlugin({
        apiKey: config.amplitude.apiKey,
        options: {
          instanceName: config.amplitude.instanceName || "loop-dev",
        },
      });

      if (config.debugMode) {
        console.debug("Amplitude plugin initialized with config:", {
          instanceName: config.amplitude.instanceName,
        });
      }

      plugins.push(amplitudePlugin);
    }

    return plugins;
  }

  static setupCoreAnalytics() {
    const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;

    if (!amplitudeApiKey && import.meta.env.MODE === "production") {
      console.warn("[Analytics Wrapper] Amplitude API key not found. Amplitude tracking will be disabled.");
    }

    try {
      return new CoreAnalytics({
        amplitude:
          import.meta.env.MODE === "production" && amplitudeApiKey
            ? {
                apiKey: amplitudeApiKey,
                instanceName: import.meta.env.VITE_ANALYTICS_INSTANCE_NAME || "loop-dev",
              }
            : undefined,
        debugMode: false && import.meta.env.MODE === "development",
        errorLogger: (error: Error) => {
          captureException(error);
          console.error("[Analytics Error]:", {
            name: error.name,
            message: error.message,
            timestamp: new Date().toISOString(),
          });
        },
      });
    } catch (error) {
      captureException(error);
    }
  }
}
