import { Button, HStack } from "@chakra-ui/react";
import { spacing } from "@design-system";
import { useTranslation } from "@hooks";

interface ConfigActionsProps {
  onRename: () => void;
  onSaveNew: () => void;
  isDisabled?: boolean;
}

export function ConfigActions({ onRename, onSaveNew, isDisabled = false }: ConfigActionsProps) {
  const { t } = useTranslation("projectProperties.postprocessorConfig");

  return (
    <HStack spacing={spacing.space["300"]}>
      <Button variant="outline" size="xs" width="71px" isDisabled={isDisabled} onClick={onRename}>
        {t("buttons.rename.label")}
      </Button>
      <Button variant="primary" size="xs" width="80px" isDisabled={isDisabled} onClick={onSaveNew}>
        {t("buttons.saveNew.label")}
      </Button>
    </HStack>
  );
}
