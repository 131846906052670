import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export interface Project3DToolbarState {
  _3DToolbarSelection: "none" | "select" | "pen" | "section" | "column" | "knitmesh";

  set3DToolbarSelection: (_3DToolbarSelection: Project3DToolbarState["_3DToolbarSelection"]) => void;
}

export interface Project3DGuiState {
  showMeasurements: boolean;
  showDirectionality: boolean;

  setShowMeasurements: (show: boolean) => void;
  setShowDirectionality: (show: boolean) => void;
}
type ProjectState = Project3DToolbarState & Project3DGuiState;

export const useProjectState = create(
  subscribeWithSelector<ProjectState>((set) => ({
    _3DToolbarSelection: "select",
    showMeasurements: false,
    showDirectionality: false,

    set3DToolbarSelection: (_3DToolbarSelection) => set(() => ({ _3DToolbarSelection })),
    setShowMeasurements: (showMeasurements: boolean) => set(() => ({ showMeasurements })),
    setShowDirectionality: (showDirectionality: boolean) => set(() => ({ showDirectionality })),
  })),
);
