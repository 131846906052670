import { create } from "zustand";
import { modelsSlice } from "./slices/models";
import { pathCollectionsSlice } from "./slices/pathCollections";
import { selectionSlice } from "./slices/selection";
import { ModelsStore } from "./types";
import { sectionsSlice } from "./slices/sections";
import { columnsSlice } from "./slices/columns";
import { knitStructuresSlice } from "./slices/knitStructures";
import { machinesSlice } from "./slices/machines";
import { postprocessorDocumentsSlice } from "./slices/postprocessorDocuments";
import { processesSlice } from "./slices/processes";

export type { ModelsStore, PathCollections, SelectedObject } from "./types";

export const useModelsStore = create<ModelsStore>(
  (set, get): ModelsStore => ({
    ...modelsSlice(set, get),
    ...pathCollectionsSlice(set, get),
    ...sectionsSlice(set, get),
    ...columnsSlice(set, get),
    ...selectionSlice(set),
    ...knitStructuresSlice(set),
    ...machinesSlice(set),
    ...postprocessorDocumentsSlice(set),
    ...processesSlice(set),
    clear: () => {
      set({
        models: [],
        pathCollections: {},
        sections: {},
        knitStructures: [],
        machines: [],
        postprocessorDocuments: [],
        columns: {},
        selectedObjects: [],
        process: null,
      });
    },
    logModels: () => console.log("logModels", get().models),
  }),
);
