import { PostprocessorDocumentsState, ModelsStore } from "../types";
import { SetState } from "zustand";
import { PostprocessorDocument } from "@models/backend";

export const postprocessorDocumentsSlice = (set: SetState<ModelsStore>): PostprocessorDocumentsState => ({
  postprocessorDocuments: [],

  setPostprocessorDocuments: (documents: PostprocessorDocument[]) => {
    set({ postprocessorDocuments: documents });
  },
  clearPostprocessorDocuments: () => {
    set({ postprocessorDocuments: [] });
  },
});
