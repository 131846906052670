import { Avatar, Text, HStack, VStack } from "@chakra-ui/react";
import { spacing } from "@design-system";
import { useSemanticTokens } from "@design-system";
import { ActiveUserInfo } from "./ActiveUsers";

export const UserItem = ({ name, email, avatar }: ActiveUserInfo) => {
  const semanticTokens = useSemanticTokens();

  return (
    <HStack
      px={spacing.space[300]}
      py={spacing.space[200]}
      spacing={spacing.space[200]}
      width="100%"
      bg={semanticTokens.surface.primary}
      borderRadius="md"
    >
      <Avatar name={name} src={avatar} width={spacing.space[600]} height={spacing.space[600]} fontSize="xs" />
      <VStack align="start" spacing={0} width="fit-content">
        <Text color={semanticTokens.text.primary} fontWeight="medium" fontSize="xs" noOfLines={1}>
          {name}
        </Text>
        <Text color={semanticTokens.text.secondary} fontSize="xs" noOfLines={1}>
          {email}
        </Text>
      </VStack>
    </HStack>
  );
};
