import { useThree } from "@react-three/fiber";
import { MOUSE } from "three";
import { OrbitControls } from "three-stdlib";
import { usePointerState } from "@hooks";

export function CameraController() {
  const { ctrlKey } = usePointerState();
  const controls = useThree().controls as OrbitControls;

  if (controls) {
    let mouseEnum = MOUSE.ROTATE;

    if (ctrlKey) {
      mouseEnum = MOUSE.DOLLY;
    }

    controls.mouseButtons = { RIGHT: mouseEnum };
  }

  return null;
}
